<template>
  <div class="HeaderX" align="center">      
    <div class="limitBar">
      <img @click="goHome()"
        v-if="$store.state.corpInfo.logo"
        :src="$root.UrlCorpImages + $store.state.corpInfo.logo"
        class="pointman logo-button"
      />        
      <div class="toolData">        
        <div
          class="pointman menu-option"
          @click="openCart"
          v-if="$route.name == 'HomePage' || ($route.name == '')">
          <div class="MyBadge" v-if="$store.state.shopping.crossSellTickets.length > 0">
            {{ $store.state.shopping.crossSellTickets.length }}
          </div>
          <v-icon :dark="$store.state.corpInfo.darkTheme" class="icon-account-cart">mdi-cart-outline</v-icon>
        </div>
        <div
          class="pointman"
          @click="goHome()"
          v-if="$route.name != 'HomePage'">
          <v-icon :dark="$store.state.corpInfo.darkTheme" class="icon-account">mdi-home</v-icon>
        </div>
        <v-menu offset-y style="z-index: 99">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="menu-option" text :dark="$store.state.corpInfo.darkTheme" v-bind="attrs" v-on="on">
              <v-icon class="mr-1" style="font-size: 17px">
                mdi-translate
              </v-icon>
              {{ $root.getTextlang() }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group mandatory v-model="$i18n.locale" @change="changeLenguage">
              <v-list-item
                v-for="(lang, index) in $root.langs"
                :key="`Lang${index}`"
                :value="lang.locale"
              >
                <v-list-item-title>{{ lang.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <div
          class="pointman menu-option-login"
          @click="GoLogin()" v-if="!$root.token">
          <v-icon :dark="$store.state.corpInfo.darkTheme" 
            class="icon-account">mdi-account-circle</v-icon>
          <span :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" class="descrip-button">{{ $t("actions.logIn") }}</span>
        </div>
        <div
          class="pointman menu-option"
          @click.stop="drawer = !drawer" v-if="$root.token && !noNavigation">
          <v-icon :color="$store.state.corpInfo.darkTheme ? 'white':'black'" class="icon-account">mdi-microsoft-xbox-controller-menu</v-icon>
        </div>
      </div>
    </div>


    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
    >
      <div style="padding: 20px">
        <div align="right" @click="exitmenu()">
          <v-icon color="var(--color-primary)" class="pointman" dark style="font-size: 35px">
            mdi-close-outline
          </v-icon>
        </div>

        <div>
          <h4 class="text-center">{{ $t("messages.welcome") }}</h4>
        </div>
        <div v-if="$root.token">
          <h4 class="text-center">
            <strong>{{ $store.getters['auth/getUserDataS'] ? $store.getters['auth/getUserDataS'].correo : "" }}</strong>
          </h4>
        </div>
      </div>

      <v-list align="left">
        <v-list-item-group v-model="MenuUser2x" color="var(--color-primary)">
          <div
            v-for="(item, i) in MenuUser2"
            :key="'MenuUser2' + i"
            @click="OpenMenu2(item)"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="item.action"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("navigation.account." + item.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
import $ from "jquery";

export default {
  name: 'Header',
  props: ["noNavigation"],
  data() {
    return {
      Inputnumeric:null,
      search:"",
      showDialog: false,
      showMyProfile: false,
      MenuUser2x: null,
      drawer: false,
      drawer2: false,
      vEntorno: null,
      items: [],
      MenuUser2: [
        { title: "mytickets.title", action: "mdi-ticket-outline", url: "boletos" },
        { title: "myProfile", action: "mdi-account-circle", url: "perfil" },
        { title: "Logout", action: "mdi-logout", url: "cerrarsesion" },
      ],
      eventTickets: false,
      culturActive: false,
      currentTab: null,
    }
  },
  mounted(){
    this.vEntorno = this.$vEntorno;
    this.Inputnumeric = this.$Inputnumeric;
    setTimeout(() => {
      this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente 
    }, 200);
  },
  methods: {
    changeLenguage() {
      this.$emit('changeLenguage')
    },
    openCart() {      
      if (this.$route.name != "shoppingCart") {
        this.$router.push({ name: "shoppingCart" });
      }
    },
    GoLogin() {      
      $('.DivMaster').hide();
      this.$root.showlogin = true;
    },
    goHome() {
     this.$root.goHome()
    },
    OpenMenu2(item) {
      switch (item.url) {
        case 'boletos':              
          if (this.$route.name != "userTickets") {
            this.$router.replace({ name: "userTickets" });
          }
          this.drawer = false;
          break;
        case "perfil":              
          if (this.$route.name != "MyProfile") {
            this.$router.replace({ name: "MyProfile" });
          }
          this.drawer = false;
          break;
        case "cerrarsesion":
          this.drawer = false;
          this.$root.Exit();
          break;
      }
    },
    exitmenu() {
      this.drawer = false;
    }
  }
}
</script>

<style scoped>
  .HeaderX{
    height: 100%;
    border-bottom: var(--color-primary) 3px solid;
    width: 100vw;
    background: var(--color-header);
  }
  .descrip-button{
    display: inline;
  }
  .logo-button {
    height: 50px; 
    opacity: 1 !important;
  }
  .menu-translate {
    border: red 1px solid !important;
  }
  .MyBadge{
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 4px 0px !important;
    background: var(--color-primary);
    border: white 1px solid;
    border-radius: 20px;
    z-index: 1;
    text-align: center !important;
    font-weight: 200;
    color: white;
    font-size: 10px;
    top: calc(20% - 15px);
    left: calc(50%);
  }
  .limitBar{
    padding: 10px 0px;
    width: 100%;
    max-width: 1300px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .toolData{
    width: 100%;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }  
  .pointman {
    cursor: pointer;
  }
  .pointman:hover {
    opacity: 0.5;
  }
  .menu-option {
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .menu-option-login {
    display: flex;
    flex-direction: column;
    font-size: 10px !important;
    justify-content: center;
    font-weight: 600;
  }
  .icon-account {
    font-size: 35px !important;
  }
  .icon-account-cart {
    font-size: 40px !important;
  }
  .toolbar {
    background: var(--color-primary-light);
    background: linear-gradient(207deg,
        var(--color-primary-light) 0%,
        var(--color-primary) 45%,
        var(--color-primary-light1) 99%);
    padding-left: .5vw;
    max-height: 56px;
  }
  @media only screen and (max-width: 1300px) {
    .HeaderX{
      padding: 10px 20px 6px 20px;
    }
    .logo-button {      
      max-height: 35px; 
    }    
    .descrip-button{
      display: none;
    }    
    .MyBadge{
      width: 20px;
      height: 20px;
      padding: 3px 0px !important;
      font-weight: bold;
      color: white;
      font-size: 9px;
      top: calc(0% - 10px);
      left: calc(50%);
    }
    .icon-account-cart {
      font-size: 30px !important;
    }
    .toolData{
      gap: 0px;
    }  
  }
</style>
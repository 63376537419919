module.exports.login= {
  timeLeft: 'Tiempo restante',
  whatsThis: {
    title: '¿Qué es esto?',
    text: 'El tiempo no se detiene: este temporizador muestra cuánto tiempo retendremos sus boletos durante el proceso de pago. Si se acaba el tiempo, otros usuarios tendrán la oportunidad de comprarlos. Sin embargo, podrá comprar sus boletos más tarde, si aún están disponibles.',
  },
  PutName:'Ingresa nombre',
  PutLastName:'Ingresa tu apellido',
  street:'Calle',
  areYouNew: '¿Eres nuevo aquí?',
  password: 'Contraseña',
  name: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  phone: 'Teléfono',
  address: 'Dirección',
  postalCode: 'Código postal',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  rememberMe: 'Recordarme',
  letMeKnow: '¡Déjame saber sobre próximos eventos y ofertas especiales!',
  letMeKnowMessage: 'Recibirás información sobre nosotros y nuestros eventos. Puedes darte de baja en cualquier momento',
  forgotPassword: '¿Olvidaste tu contraseña?',
  legalInformation: {
    byContinuing: 'Al continuar con la página siguiente, usted acepta los',
    terms: 'términos',
    and: 'y',
    conditions: 'condiciones',
    andYouUnderstand: 'y entiendo que la información será utilizada como se describe en nuestro',
    privacyPolicies: 'políticas de privacidad.',
  },
  loginWithFacebook: 'Iniciar con Facebook',
  signOutFacebook: 'Cerrar sesión',
  doYouHaveAccount: '¿Ya tienes una cuenta?',
  recoverPassword: 'Recuperar contraseña',
  doYouNeddtoRecover: '¿Necesitas recuperar tu contraseña? Ingrese su correo electrónico para continuar.',
  typeTheCode: 'Se envió un código a su correo electrónico, escríbalo para continuar',
  code: 'Código',
  typeNewPassword: 'Por favor escriba su nueva contraseña',
  backToLogin: 'volver a iniciar sesión',
}
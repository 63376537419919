module.exports.actions = {
  categorias:[
    {title:'', icon: 'mdi-domain' },
    {title:'Auberges de jeunesse', icon: 'mdi-domain' },
    {title:'Musée', icon: 'mdi-domain' },
    {title:'Événement', icon: 'mdi-domain' },
    {title:'Théâtre', icon: 'mdi-domain' },
    {title:'Centre culturel', icon: 'mdi-domain' },
    {title:'Auditorium', icon: 'mdi-domain' },
    {title:'Audiovisial', icon: 'mdi-domain' },
    {title:'Atelier', icon: 'mdi-domain' },
  ],
  tickets:'Billet',
  packets:'Paquets',
  price:'Prix',
  amount:'Montant',
  place:'Lieu',
  SeeTickets:'Voir les billets',
  logIn: 'Connexion',
  logInFacebook: 'Se connecter avec Facebook',
  Logout: 'Déconnexion',
  signIn: 'Connexion',
  next: 'Suivant',
  seeTickets: 'voir les billets',
  goBack: 'Retour',
  cancel: 'Annuler',
  canceled: 'ANNULÉ',
  save: 'Enregistrer',
  change: 'Modifier',
  toClose: 'Pour fermer',
  search: 'Chercher',
  printTickets: 'Imprimer les billets',
  understand: 'Événements à venir', 
  nextEvent:'Upcoming Events',
  findTicket:'Trouver un billet',
  Events:'Evénements',
  EventS2:'ÉVÉNEMENTS',
  limitedAvai:'Disponible',
  limitedAvai2:'Épuisé',
  about:'à propos',
  ticketFor:'Billet pour',
  limitEdad:"Limite d'âge : Il n'y a aucune restriction. Ils paient un ticket à partir de : 3 ans. Restrictions : L'entrée avec de la nourriture, des boissons, des caméras vidéo... est interdite.",
  cardinfo:{
    tickets:'Billet(s)',
    ticketsUp: 'BILLET(S)',
    section:'SECTION',
    row:'LIGNE',
    colum:'COLONNE',
    seat:'SEAT',
    unitPrice: 'PRIX UNITÉ',
    unitPrice2: 'Prix unitaire',
    price:'PRIX',
    total: 'TOTAL',
    subtotal: 'SOUS-TOTAL',
    amount:'MONTANT',
    numberTickets: "Nombre de billets",
    dateEvent: "Date de l'événement",
    timeEvent: "Heure Événement",
  },
  step:{
    usuario:'Données utilisateur',
    pay:'Détails du paiement',
    confirma:"Confirmation d'achat",
    confirma1:'CONFIRMER',
    confirma2:'ACHAT',
  },
  paycard:{
    cardname: 'Nom du titulaire de la carte',
    card:'N° de carte',
    exp:'Expiration',
    requi:'Ce champ est obligatoire',
    terminos:"Conditions d'utilisation",
    read:"J'ai lu le",
  },
  pdf:{
    oops:"Oups ! Votre navigateur ne prend pas en charge les PDF !",
    download:'Télécharger le fichier'
  },
  next:'Suivant',
  shop:'ACHETER',
  resume: 'Résumé de paiement',
  information: 'Informations',
  more:"Plus d'informations",
  address: "Informations sur l'adresse",
  shoppingCart: 'Chariot',
  categories : {
    0: 'ACCÈS GÉNÉRAL',
    1: 'ACTIVITÉS',
    2: 'COMBINAISONS'
  },
  addToCart: 'Ajouter la sélection au panier',
  ready: 'Prêt',
  shopSelectedTickets: 'Acheter le(s) billet(s) sélectionné(s)',
  shopDetails: "Détails d'achat",
  cancelShop: "Annuler l'achat",
  paymentStatus: {
    notPaid: "Refusé",
    paid: "Payé"
  }
}
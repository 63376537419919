module.exports.login= {
  timeLeft: 'Temps restant',
  whatsThis: {
    title: "Qu'est-ce que c'est?",
    text: "Le temps ne s\'arrête pas - Ce minuteur indique combien de temps nous conserverons vos billets pendant le processus de paiement. Si le temps est écoulé, d'autres utilisateurs auront la possibilité de les acheter. Cependant, vous pourrez acheter vos billets plus tard, s'ils sont encore disponibles.",
  },
  PutName:'Entrez le nom',
  PutLastName:'Entrez votre nom de famille',
  street:'Rue',
  areYouNew: 'Êtes-vous nouveau ici?',
  password: 'Mot de passe',
  name: 'Nom',
  lastName: 'Nom de famille',
  email: 'E-mail',
  phone: 'Téléphone',
  address: 'Adresse',
  postalCode: 'Code postal',
  country: 'Pays',
  state: 'État',
  city: 'Ville',
  rememberMe: 'Souviens-toi de moi',
  letMeKnow: 'Informez-moi des événements à venir et des offres spéciales!',
  letMeKnowMessage: "Vous recevrez des informations sur nous et nos événements. Vous pouvez vous désinscrire à n'importe quel moment",
  forgotPassword: 'Mot de passe oublié ?',
  legalInformation: {
    byContinuing: 'En continuant à la page suivante, vous acceptez les',
    terms: 'termes',
    and: 'et',
    conditions: 'conditions',
    andYouUnderstand: 'et comprenez que les informations seront utilisées comme décrit dans notre',
    privacyPolicies: 'politiques de confidentialité.',
  },
  loginWithFacebook: 'Connexion avec Facebook',
  signOutFacebook: 'Se déconnecter de Facebook',
  doYouHaveAccount: 'Avez-vous déjà un compte?',
  recoverPassword: 'Récupérer le mot de passe',
  doYouNeddtoRecover: 'Avez-vous besoin de récupérer votre mot de passe? Entrez votre e-mail pour continuer.',
  typeTheCode: 'Un code a été envoyé à votre adresse e-mail, saisissez-le pour continuer',
  code: 'Code',
  typeNewPassword: 'Veuillez entrer votre nouveau mot de passe',
  backToLogin: 'Retour à la connexion',
}
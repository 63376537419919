<template>
  <v-dialog id="warningDialog" scrollable persistent max-width="700" v-model="showDialog" transition="dialog-bottom-transition">
    <v-card style="max-height:800px;">
      <v-card-title class="text-h6"><b>AVISO DE PRIVACIDAD</b></v-card-title>
      <v-card-text @scroll="onMyScroll" id="cardText">
        <p><b>AVISO DE PRIVACIDAD SIMPLIFICADO DEL BOLETO ELECTRÓNICO RECORRIDO NOCTURNO Y PROYECCIÓN AUDIO LUMÍNICA CHICHÉN ITZÁ Y UXMAL.</b></p>
        <p class="text-justify">El Patronato de las Unidades de Servicios Culturales y Turísticos del Estado de Yucatán, CULTUR, es el responsable del tratamiento de los datos personales que nos proporcione, los cuales serán protegidos conforme a lo dispuesto por la Ley General de Protección de Datos Personales en posesión de Sujetos Obligados, y demás normatividad que resulte aplicable.</p>
        <p class="text-justify"><b>¿Para que fines utilizaremos sus datos personales?</b></p>
        <p class="text-justify">Los datos personales que solicitamos los utilizaremos para las siguientes finalidades:</p>
        <table class="mb-2">
          <tbody>                    
            <tr class="blue-grey darken-4">
              <th class="white--text" rowspan="2">Finalidad</th>
              <th class="white--text pa-2" colspan="2">¿Requieren consentimiento del titular?</th>
            </tr>           
            <tr class="blue-grey lighten-5 text-center">
              <td>NO</td>
              <td>SI</td>                                                                          
            </tr>                 
            <tr>
              <td class="blue-grey darken-4 white--text pa-2">Generar los datos de identificación para la compra del boleto en línea.</td>
              <td class="text-center">X</td> 
              <td> </td>                          
            </tr>
            <tr>
              <td class="blue-grey darken-4 white--text pa-2">Generar el cobro por la adquisición del boleto</td> 
              <td class="blue-grey lighten-5 text-center">X</td> 
              <td class="blue-grey lighten-5"></td>                         
            </tr>
            <tr>
              <td class="blue-grey darken-4 white--text pa-2">Generar el informe estadístico del área</td> 
              <td class="table-light text-center">X</td> 
              <td class="table-light"></td>                         
            </tr>                    
          </tbody>    
        </table>
        <p class="text-justify">En caso de que no desee que sus datos personales sean tratados para estas finalidades que requieren su consentimiento podrá indicarlo en: la Unidad de Transparencia de este sujeto Obligado mediante una solicitud de ejercicio de derechos ARCO.</p>
        <p class="text-justify"><b>¿Con quién compartimos su información y para que fines?</b></p>
        <p class="text-justify">Se informa que no se realizarán transferencias de datos personales, salvo aquéllas que sean necesarias para atender requerimientos de información de una autoridad competente, que estén debidamente fundados y motivados.</p> 
        <p class="text-justify">Si desea conocer nuestro aviso de privacidad integral, lo podrá consultar en:</p> 
        <p><a href="https://sites.google.com/culturyucatan.com/transparencia/avisos-de-privacidad" target="_blank" rel="noopener noreferrer">AVISOS DE PRIVACIDAD DE CULTUR</a></p>
        
      </v-card-text>   
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="var(--color-primary)" @click="$root.showWarningDialog = false" :disabled ="validScroll">
          ACEPTAR
        </v-btn>
      </v-card-actions> 
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WarningInfoDialog',
  props: ['showDialog'],
  data() {
    return {
      validScroll: true
    }
  },
  watch: {
    showDialog: function (value) {
      if(value) {
        this.validScroll = true
        setTimeout(() => {
          document.getElementById('cardText').scroll({
            top: 0,
            behavior: "smooth",
          });
        }, 10);
      }
    }
  },
  methods: {
    onMyScroll(e) {
      if(e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight - 20) this.validScroll = false
    }
  }
}
</script>
import Vue from "vue";

Vue.prototype.$DesignLayout_CVListView = {
  styleContainer: {
    maxWidth: "400px",
    borderRadius: "5px",
    boxShadow: "",
    border: "0px",
    background: "white",
  },
  styleItem: {
    border: "",
    boxShadow: "0 2px 5px -1px rgba(51, 51, 51, 0.23)",
    borderRadius: "3px",
    background: "white",
    fontSizeTitle: "14px",
    fontSizeSubtitle: "11px",
  },
  colors: {
    firstColor: "#444",
    secondColor: "black",
    iconsColor: "black",
    iconShadows: "#ccc",
  },
};

Vue.prototype.$DesignLayout_EConfig = {
  Loyaout: "Master",
  Showloading: false,
  ShowloadingBig: false,
  Newloyaut: false,
  Place: "",
  previewImage: null,
  ShowMapSvg: false,
  ShowMapPrice: false,
  ShowVCardPlace: false,
  ShowVCardPlaceList: false,
  showToolseating: false,
  preview: false,
  showShapeTools: false,
  showTextTools: false,
  showListLayer: false,
  showSeatCurve: false,
  showSeatRotate: false,
  showSeatPadding: false,
  showEditPoint: false,
  showAssignLetters: false,
  DeleteSeatActive: false,
  SelectSeatActive: false,
};

Vue.prototype.$DesignLayout_CMenuXConfig = {
  Menus: [
    {
      options: [
        {
          name: "",
          icon: "l",
          type: 1,
          option: "",
          staySelected: false,
          defaultSelec: false,
        },
      ],
      FontColor: "",
      FontAnimateColor: "",
      FontActiveColor: "",
      BackMenuColor: "",
    },
    {
      options: [
        {
          name: "Load Layer",
          icon: "view_carousel",
          type: 1,
          option: "LoadLayer",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Proyect Map",
          icon: "history_edu",
          type: 1,
          option: "ProyectMap",
          staySelected: false,
          defaultSelec: true,
        },
        {
          name: "Return",
          icon: "reply",
          type: 1,
          option: "ShowVCardPlaceList",
          staySelected: false,
          defaultSelec: false,
        },
      ],
      FontColor: "white",
      FontAnimateColor: "#31a63a99",
      FontActiveColor: "#b6b3b3",
      BackMenuColor: "#31a63a99",
    },
    {
      options: [
        {
          name: "Remove Point",
          icon: "remove_circle_outline",
          type: 1,
          option: "RemoveLastPointDraw",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Color Draw",
          icon: "palette",
          type: 1,
          option: "SetColorShape",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Mirrow Draw H",
          icon: "swap_horiz",
          type: 1,
          option: "MirrowDrawH",
          staySelected: false,
          defaultSelec: false,
        },

        {
          name: "Mirrow Draw V",
          icon: "swap_vert",
          type: 1,
          option: "MirrowDrawV",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Draw Shape",
          icon: "format_shapes",
          type: 1,
          option: "DrawShape",
          staySelected: true,
          defaultSelec: false,
        },
        {
          name: "Draw Circle",
          icon: "circle",
          type: 1,
          option: "DrawCircle",
          staySelected: true,
          defaultSelec: false,
        },
        {
          name: "Save Draw",
          icon: "save",
          type: 1,
          option: "SaveDraw",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Cancel Edit",
          icon: "cancel",
          type: 1,
          option: "CancelDraw",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Return",
          icon: "reply",
          type: 1,
          option: "ReturnDashBoard",
          staySelected: false,
          defaultSelec: false,
        },
      ],
      FontColor: "white",
      FontAnimateColor: "#002a9a99",
      FontActiveColor: "#b6b3b3",
      BackMenuColor: "#002a9a99",
    },
    {
      options: [
        {
          name: "Save Seat",
          icon: "save",
          type: 1,
          option: "SaveSeat",
          staySelected: false,
          defaultSelec: false,
        },
        {
          name: "Back Draw",
          icon: "reply",
          type: 1,
          option: "BackDraw2",
          staySelected: false,
          defaultSelec: false,
        },
      ],
      FontColor: "white",
      FontAnimateColor: "#e91e6399",
      FontActiveColor: "#b6b3b3",
      BackMenuColor: "#e91e6399",
    },
    {
      options: [
        {
          name: "Back Draw",
          icon: "reply",
          type: 1,
          option: "ReturnDashBoard2",
          staySelected: false,
          defaultSelec: false,
        },
      ],
      FontColor: "white",
      FontAnimateColor: "#9c27b099",
      FontActiveColor: "#b6b3b3",
      BackMenuColor: "#9c27b099",
    },
  ],
  index: 0,
  active: -1,
};

Vue.prototype.$DesignLayout_ESvgMap = {
  circle: [],
  path: [],
  id_section: null,
  id_shape: null,
  id_circle: null,
  color: "black",
  color2: "#CCC",
  dialogSave: false,
  dialogRename: false,
  DrawBegin: false,
  SelectShape: 0,
  GroupSelectSeat: 0,
  Figure: 1,
  height: 768,
  IniMap: {
    x: 142.71154479797679,
    y: 90.39136419299282,
    k: 0.757858283255199,
    limit1: 1 / 4,
    limit2: 30,
  },
  MyList: [],
  MyListOri: [],
  opacity: 0.5,
  radius: 3,
  radius2: 20.1,
  selectedCircleRadius: 20.1,
  seating_Radio: 13,
  shapeM: [],
  shapes: [],
  seats: [],
  textLabel: {
    fontSize: 20,
    r: 0,
    x: 491.8492736816406,
    y: 466.7265930175781,
  },
  textLabels: [],
  width: 1024,
  ZoomData: {
    x: 101.57210991586959,
    y: 62.360729035233874,
    k: 0.757858283255199,
  },
};

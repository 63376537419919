module.exports.navigation = {
  home: 'Inicio',
  centerTickets: 'Centros pasetotal',
  help: 'Ayuda',
  account: {
    me: 'Mi Cuenta',
    mytickets: {
      title: 'Mis Boletos',
      items: ['TICKETS DE ADMISION GENERAL', 'TICKETS DE EVENTOS CON FECHA', 'Mis boletos']
    },
    myProfile: 'Mi Perfil',
    Logout: 'Cerrar Sesión'
  },
  accountData: "Datos del Usuario",
  accountAddressData: "Domicilio del Usuario",
}
module.exports.messages = {
  welcome: 'Welcome!',
  validationx:'Starting charge validation',
  TransTickets:'Your transaction has',
  MaxTickets:'Limite de tickets par transaction',
  TokenInvalid:'Login or Register!',
  SelectTickets:'Select a ticket!',
  generateorden:'Generating order',
  finishtransac:'Finalizing transaction',
  confirmpay:'Confirming payment',
  invalidUser: 'Invalid user!',
  desactivate: 'Disabled for now',
  failedToLogin: 'Failed to login!',
  ticketsNumberMustZero: 'select at least one ticket!',
  ticketsNumberMustLessHundred: 'The number of tickets must be less than 20',
  ticketsMustMatch: 'The number of selected tickets does not match your previous selection!',
  ticketsUpdated: 'Updated cart!',
  checkTheFormData: 'Check the form data',
  acceptYermsConditions: 'Accept the terms and conditions',
  validation: {
    required: 'This field is required.',
    isEmail: 'The email must be valid.',
    validCard: 'Invalid Card',
    isPhone: 'This phone is invalid',
    validLocation: 'Please select a location from the options',
    invalidFields: 'Invalid Fields',
    ValidateReCAPTCHA: 'Validate the reCAPTCHA',
    invalidCode: 'Invalid Code',
    InvalidEmailPassword: 'Invalid email or password'
  },
  tarjetValid:'Valid Card',
  permissionsRequired: 'Permissions required.',
  needEmailMsg: 'Email required. Do you want to grant the permissions?',
  tryAgain: 'Try again',
  canceltickets:'Want to remove tickets from this site?',
  UpdateInfoUser:'Updated data',
  msgConfirmation1:'Once your tickets have been used, they cannot be used again.',
  updatedPass: "Updated password",
  correctCode: "Correct code. Enter new password.",
  successfulPurchase: 'Successful purchase!',
  netpayProblem: 'Collection service error. Please try again later.',
  noEvents: "There are no events for today",
  nextCloseDate: "Next closest date",
  existingUser: "This user already exists",
  timeoutExpired: "The waiting time for the purchase has expired",
  soon: "Soon",
  reservationProblem: [
    "There are not enough places available for any section",
    "Some of the seats are no longer available",
    "Number of places not available",
    "Not enough places available"
  ],
  interest: "This might interest you...",
  ticketUploadError: "Error loading tickets",
  seeTicketsLegend: 'Go to the "My Tickets" section to print your tickets',
  paymentProcess: {
    validateCard: '1 of 5 - Validating Card...',
    generateTransaction: '2 of 5 - Generating transaction...',
    makeCharge: '3 of 5 - Making payment...',
    validationRequired: 'Card validation is required, please fill in the form details...',
    send3dSecure: '4 of 5 - Performing validation...',
    verifyPayment: '5 of 5 - Verifying payment and generating tickets. Wait a moment...'
  },
  paymentErrors: {
    ticketsError: "An error occurred while generating the tickets, please contact support.",
    payError: "An error occurred while making the payment",
    netpayError: "An unexpected error occurred, please refresh the page and try again"
  },
  noShopping: "No shopping",
  noTickets: "There are no tickets available yet",
  cardDetailsNotice: "Enter the data corresponding to the card registered in your account.",
  fillAccountData: "Fill in with the account data",
  notFoundMess: {
    defaultTitle: "Not Found",
    defaultLegend: "Sorry... Something went wrong",
    notFoundEvent: "Unfortunately, the event you are trying to access may not be available at this time or may have been cancelled."
  }
}
export const shopping = {
  namespaced: true,
  state: {
    crossSellTickets: [],
    regularSellTickets: [],
    reserveToken: null,
    transactionToken: null,
    cardToken: null,
    stadisticsValue: [],
    goRegularSale: false,
    goCrossSale: false
  },
  actions: {
    updateRegularPurchase({ commit }, tickets) {
      commit("updateRegularSell", tickets);
    },
    updateCrossPurchase({ commit }, tickets) {
      commit("updateShoppingCart", tickets);
    },
    setTokenReservation({ commit }, token) {
      commit("setReserveToken", token);
    },
    resetRegularSell({ commit }) {
      commit("resetRegularSell");
    },
    resetCrossedSell({ commit }) {
      commit("resetCrossedSell");
    },
    upgradeTransactionToken({ commit }, tokenValue) {
      commit("upgradeTransactionToken", tokenValue);
    },
    setCardToken({ commit }, cardTokenValue) {
      commit("setCardToken", cardTokenValue);
    },
    setStadisticsValue({ commit }, stadisOptions) {
      commit("setStadisticsValue_M", stadisOptions);
    },
    setFlagBackRegularBuy({ commit }, regularBuyFlag) {
      commit("setFlagBackRegularBuy_M", regularBuyFlag);
    },
    setFlagBackCrossBuy({ commit }, crossBuyFlag) {
      commit("setFlagBackCrossBuy_M", crossBuyFlag);
    },
    resetStadisticsValue({ commit }) {
      commit("resetStadisticsValue_M");
    }
  },
  mutations: {
    updateRegularSell(state, tickets) {
      state.regularSellTickets = tickets
    },
    updateShoppingCart(state, tickets) {
      state.crossSellTickets = tickets
    },
    clearRegularSellTickets(state){
      state.regularSellTickets = []
    },    
    setReserveToken(state, token) {
      state.reserveToken = token
    },    
    resetRegularSell(state) {
      state.regularSellTickets = []
      state.reserveToken = null 
    },    
    resetCrossedSell(state) {
      state.crossSellTickets = []
    },   
    upgradeTransactionToken(state, tokenValue) {
      state.transactionToken = tokenValue
    },
    setCardToken(state, cardTokenValue) {
      state.cardToken = cardTokenValue
    },
    setStadisticsValue_M(state, stadisOptions) {
      state.stadisticsValue = stadisOptions
    },
    setFlagBackRegularBuy_M(state, regularFlag) {
      state.goRegularSale = regularFlag
    },
    setFlagBackCrossBuy_M(state, crossFlag) {
      state.goCrossSale = crossFlag
    },
    resetStadisticsValue_M(state) {
      state.stadisticsValue.forEach((option) => {
        option.cantidad = 0
      })
    }
  },
  getters: {
    getNumberRegular(state) {
      let cantidadT = 0
      state.regularSellTickets.forEach(({ cantidad }) => {
        if(cantidad) cantidadT += Number(cantidad);
        else cantidadT += 1;
      })
      return cantidadT
    },
    getRegularTotal(state) {
      let totalT = 0
      state.regularSellTickets.forEach(({ total, price }) => {
        if(total) totalT += Number(total);
        else totalT += Number(price);
      })
      return totalT
    },
    getNumberCrossed(state) {
      let cantidadT = 0
      state.crossSellTickets.forEach(({ cantidad }) => {
        cantidadT += Number(cantidad);
      })
      return cantidadT
    },
    getCroosedTotal(state) {
      let totalT = 0
      state.crossSellTickets.forEach(({ total }) => {
        totalT += Number(total);
      })
      return totalT
    },
    getTotalAvailableTickets(state) {
      let quantity = 0
      state.stadisticsValue.forEach(({ cantidad }) => {
        quantity += Number(cantidad);
      })
      return quantity
    }
  }
};

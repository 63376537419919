<template>
  <div class="MyFooter mx-auto py-10 px-5">
    <div class="limitFooter mx-auto">
      <v-row class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-space-around align-start">
        <v-col cols="12" md="4" lg="4" xl="4"
            v-for="(column, index) in $store.state.corpInfo.footerInfo" :key="index"
            class="px-7 px-md-2 px-lg-2 px-xl-2"
          >
          <div v-if="column.logo" class="text-center">
            <img :src="$root.UrlCorpImages + column.logo" 
              class="normallogo" 
            />
          </div>
          <div v-if="column.html" 
            v-html="column.html" 
            style="max-width: 100%; overflow-wrap: break-word !important;"
          ></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dynamicFooter'
}
</script>

<style scoped>
.normallogo {
  margin: 0px auto;
  max-height: 60px;
}
.MyFooter {
  background-color: var(--color-footer);
  width: 100%;
}
.limitFooter {
  max-width: 1400px;
}
@media (max-width: 700px) {
  .normallogo {
    margin-top: 20px;
    max-height: 50px;
  }
}
</style>
module.exports.actions = {
  categorias:[
    {title:'', icon: 'mdi-domain' },
    {title:'Tourist Inns', icon: 'mdi-domain' },
    {title:'Museum', icon: 'mdi-domain' },
    {title:'Event', icon: 'mdi-domain' },
    {title:'Theater', icon: 'mdi-domain' },
    {title:'Cultural Center', icon: 'mdi-domain' },
    {title:'Auditorium', icon: 'mdi-domain' },
    {title:'Audiovisial', icon: 'mdi-domain' },
    {title:'Workshop', icon: 'mdi-domain' },
  ],
  tickets:'Ticket',
  packets:'Packages',
  price:'Price',
  amount:'Amount',
  place:'Place',
  SeeTickets:'See Tickets',
  logIn: 'Log in',
  logInFacebook: 'Log in with facebook',
  Logout: 'Logout',
  signIn: 'Sign in',
  next: 'Next',
  seeTickets: 'see tickets',
  goBack: 'Back',
  cancel: 'Cancel',
  canceled: 'CANCELLED',
  save: 'Save',
  change: 'Change',
  toClose: 'To close',
  search: 'Search',
  printTickets: 'Print tickets',
  understand: 'Okey, I understand', 
  nextEvent:'Upcoming Events',
  findTicket:'Find Ticket',
  Events:'Events',
  EventS2:'EVENTS',
  limitedAvai:'Available',
  limitedAvai2:'Sold out',
  about:'About',
  ticketFor:'Ticket for',
  limitEdad:'Age limit: There are no restrictions. They pay ticket from: 3 years. Restrictions: Entry with food, drinks, video cameras... is prohibited.',
  cardinfo:{
    tickets:'Ticket(s)',
    ticketsUp: 'TICKET(S)',
    section:'SECTION',
    row:'ROW',
    colum:'COLUMN',
    seat:'SEAT',
    unitPrice: 'UNIT PRICE',
    unitPrice2: 'Unit price',
    price:'PRICE',
    total: 'TOTAL',
    subtotal: 'SUBTOTAL',
    amount:'AMOUNT',
    numberTickets: "Number of Tickets",
    dateEvent: "Date Event",
    timeEvent: "Time Event",
  },
  step:{
    usuario:'User data',
    pay:'Details of payment',
    confirma:'Purchase confirmation',
    confirma1:'CONFIRM',
    confirma2:'PURCHASE',
  },
  paycard:{
    cardname: 'Card owner name',
    card:'Card No.',
    exp:'Expiration',
    requi:'This field is required',
    terminos:'Terms and Conditions',
    read:'I have read the',
  },
  pdf:{
    oops:"Oops! Your browser doesn't support PDFs!",
    download:'Download File'
  },
  next:'NEXT',
  shop:'BUY',
  resume: 'Payment Summary',
  information: 'Information',
  more:'More Information',
  address: 'Address Information',
  shoppingCart: 'Shopping Cart',
  categories : {
    0: 'GENERAL ACCESS',
    1: 'ACTIVITIES',
    2: 'COMBINATIONS'
  },
  addToCart: 'Add to shopping cart',
  ready: 'Ready',
  shopSelectedTickets: 'Buy selected ticket(s)',
  shopDetails: 'Purchase details',
  cancelShop: "Cancel purchase",
  paymentStatus: {
    notPaid: "Refused",
    paid: "Paid"
  }
}
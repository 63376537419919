module.exports.messages = {
  welcome: 'Bienvenue!',
  validationx:'Validation des frais de départ',
  TransTickets:'Votre transaction a',
  MaxTickets:'Limite de billets par transaction',
  TokenInvalid:'Login or Register!',
  SelectTickets:'Sélectionnez un billet!',
  generateorden:'Génération de la commande',
  finishtransac:'Finalisation de la transaction',
  confirmpay:'Confirmation du paiement',
  invalidUser: 'Utilisateur invalide!',
  desactivate: "Désactivé pour l'instant",
  failedToLogin: 'Échec de la connexion!',
  ticketsNumberMustZero: 'Le nombre de billets doit être supérieur à zéro',
  ticketsNumberMustLessHundred: 'Le nombre de billets doit être inférieur à 20',
  ticketsMustMatch: "Le nombre de billets sélectionnés ne correspond pas à votre sélection précédente!",
  ticketsUpdated: 'Panier mis à jour!',
  checkTheFormData: 'Vérifier les données du formulaire',
  acceptYermsConditions: 'Accepter les termes et conditions',
  validation: {
    required: 'Ce champ est obligatoire.',
    isEmail: "L'email doit être valide.",
    validCard: 'Carte invalide',
    isPhone: 'Le téléphone est invalide',
    validLocation: 'Veuillez sélectionner un emplacement parmi les options',
    invalidFields: 'Champs Invalides',
    ValidateReCAPTCHA: 'Valider le reCAPTCHA',
    invalidCode: 'Code Invalide',
    InvalidEmailPassword: 'Email ou mot de passe invalide'
  },
  tarjetValid:'Carte valide',
  permissionsRequired: 'Autorisations requises.',
  needEmailMsg: 'Email (requis). Voulez-vous accorder des autorisations?',
  tryAgain: 'Réessayer',
  canceltickets:'Voulez-vous supprimer des billets de ce site?',
  UpdateInfoUser:'Données mises à jour',
  msgConfirmation1:"Une fois vos billets utilisés, ils ne peuvent plus être utilisés.",
  updatedPass: "Mot de passe mis à jour",
  correctCode: "Code correct. Entrez un nouveau mot de passe.",
  successfulPurchase: 'Achat réussi !',
  netpayProblem: 'Erreur du service de collecte. Veuillez réessayer plus tard.',
  noEvents: "Il n'y a pas d'événements pour aujourd'hui",
  nextCloseDate: "Prochaine date la plus proche",
  existingUser: "Cet utilisateur existe déjà",
  timeoutExpired: "Le temps d'attente pour l'achat a expiré",
  soon: "Bientôt",
  reservationProblem: [
    "Il n'y a pas assez de places disponibles pour la section",
    "Certaines places ne sont plus disponibles",
    "Nombre de places non disponible",
    "Pas assez de places disponibles"
  ],
  interest: "Cela pourrait vous intéresser...",
  ticketUploadError: "Erreur lors du chargement des billets",
  seeTicketsLegend: 'Rendez-vous dans la rubrique "Mes Billets" pour imprimer vos billets',
  paymentProcess: {
    validateCard: "1 sur 5 - Validation de la carte...",
    generateTransaction: "2 sur 5 - Génération de transaction...",
    makeCharge: "3 sur 5 - Effectuer le paiement...",
    validationRequired: "La validation de la carte est requise, veuillez remplir les détails du formulaire...",
    send3dSecure: "4 sur 5 - Effectuer la validation...",
    verifyPayment: "5 sur 5 - Vérification du paiement et génération des tickets. Attendez un moment..."
  },
  paymentErrors: {
    ticketsError: "Une erreur s'est produite lors de la génération des tickets, veuillez contacter le support.",
    payError: "Une erreur s'est produite lors du paiement",
    netpayError: "Une erreur inattendue s'est produite, veuillez actualiser la page et réessayer"
  },
  noShopping: "Pas de courses",
  noTickets: "Il n'y a pas encore de billets disponibles",
  cardDetailsNotice: "Entrez les données correspondant à la carte enregistrée dans votre compte.",
  fillAccountData: "Remplir avec les données du compte",
  notFoundMess: {
    defaultTitle: "Pas trouvé",
    defaultLegend: "Des excuses... Quelque chose s'est mal passé",
    notFoundEvent: "Malheureusement, l'événement auquel vous tentez d'accéder n'est peut-être pas disponible pour le moment ou a peut-être été annulé."
  }
}
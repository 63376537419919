module.exports.tableHeaders = {
  rowsporpage: 'Rows per page',
  itemsPerPageAllText: 'All',
  eventName: 'Event name',
  city: 'City',
  state: 'State',
  eventPlace: 'Event place',
  zone: 'Zone',
  Seat: 'Seat',
  row: 'Row',
  column: 'Column',
  price: 'Price',
  transac:'TRANSACTION',
  dtransac:'TRANSACTION DATE',
  ttransac:'TRANSACTION TIME',
  ResumShop:'Purchase Summary',
  Amount:'Amount',
  Nomber:'Ticket Name',
  Unitary:'Unit Cost',
  subtotal:'Subtotal',
  DataPay:'Payment data',
  proof: "Proof of payment",
  status: "Status"
}
<template>
  <div style="width: 100%;">
    <DynamicFooter class="mx-auto" v-if="$store.state.corpInfo.footerInfo && $store.state.corpInfo.footerInfo.length > 0"/>
  </div>
</template>

<script>
import DynamicFooter from './Footers/DynamicFooter.vue'

export default {
  name: 'FooterX2',
  components: {
    DynamicFooter
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
module.exports.navigation = {
  home: 'Home',
  centerTickets: 'pasetotal centers',
  help: 'Help',
  account: {
    me: 'My account',
    mytickets: {
      title: 'My tickets',
      items: ['GENERAL ADMISSION TICKETS', 'DATED EVENTS TICKETS', 'My tickets']
    },
    myProfile: 'My profile',
    Logout: 'Logout'
  },
  accountData: "User data",
  accountAddressData: "User's address",
}
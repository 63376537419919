<template>
  <div>
    <v-card-title>
      <div :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" style="font-size: 28px !important;">
        <strong>
          {{ $t("login.recoverPassword") }}
        </strong>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row v-if="step === 0">
        <v-col cols="12" :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" 
          style="font-size: 15px;" class="mb-0 pb-0">
          {{ $t("login.doYouHaveAccount") }}
          <strong
            style="color: var(--color-primary)"
            class="pointman"
            @click="$emit('changeView', 'showLogin')"
          >
            {{ $t("actions.logIn") }}
          </strong>
        </v-col>
        <v-col cols="12" :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" 
          style="font-size: 15px;" class="mb-0 pb-0">
          {{ $t("login.doYouNeddtoRecover") }}
        </v-col>
        <v-form ref="formRecoverPassword" class="pa-2" @submit="sendMail">
          <v-row>
            <v-col cols="12" class="mb-0 pb-0">
              <v-text-field
                :dark="$store.state.corpInfo.darkTheme"
                :background-color="$store.state.corpInfo.darkTheme ? '#1C1C1C99':'#E0E0E099'"
                color="var(--color-primary)"
                v-model="email"
                outlined
                :label="$t('login.email')"
                :rules="[rules.required, rules.isEmail]"
                v-on:keyup.enter="sendMail"
                type="email"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" align="center" class="pt-0 mt-0">
              <div id="grecaptcha_element_recoverPassword"></div>
            </v-col>
            <v-col cols="12" align="center" class="pt-0 mt-0">
              <v-btn :disabled="disabled" 
                depressed 
                :dark="$store.state.corpInfo.darkTheme" 
                style="background-color: var(--color-primary); font-size: 16px" 
                :loading="loading" @click="sendMail">
                {{ $t("actions.next") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
      <v-row v-if="step === 1">
        <v-form ref="formVerifyCode" class="pa-2">
          <v-row>
            <v-col cols="12" 
              :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]"
              style="font-size: 15px;" class="mb-0 pb-0">
              {{ $t("login.typeTheCode") }}
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
              <div class="mx-auto d-flex flex-column justify-center align-center">
                <v-otp-input style="max-width: 300px"
                  class="mx-auto order-first"
                  v-model="code"
                  type="number"
                  :dark="$store.state.corpInfo.darkTheme"
                  :disabled="loading"
                  :label="$t('login.code')"
                ></v-otp-input>
              </div>
              <div :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]"
                class="text--caption">
                {{ $t("home.lostMyPassMessage.part1") }}
                <span class="font-weight-bold">{{ $t("home.lostMyPassMessage.part2") }}</span>
              </div>
            </v-col>
            <v-col cols="12" align="center" class="pt-0 mt-0">
              <v-btn @click="verifyCode" 
                depressed 
                :disabled="code.length !== 6"
                :dark="$store.state.corpInfo.darkTheme"
                style="background-color: var(--color-primary); font-size: 16px" 
                :loading="loading">
                {{ $t("actions.next") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
      <v-row v-show="step === 2">
        <v-form ref="formNewPassword" class="pa-2" @submit="newPassword">
          <v-row>
            <v-col cols="12" style="font-size: 15px;" 
              :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" 
              class="mb-0 pb-0">
              {{ $t("login.typeNewPassword") }}
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
              <v-text-field
                :dark="$store.state.corpInfo.darkTheme"
                :background-color="$store.state.corpInfo.darkTheme ? '#1C1C1C99':'#E0E0E099'"
                color="var(--color-primary)"
                v-model="newPass"
                outlined
                :append-icon="
                  seePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                @click:append="seePassword = !seePassword"
                v-on:keyup.enter="newPassword"
                :rules="[rules.required]"
                :type="seePassword ? 'text' : 'password'"
                :label="$t('login.password')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" align="center" class="pt-0 mt-0">
              <v-btn @click="newPassword" 
                depressed 
                :dark="$store.state.corpInfo.darkTheme" 
                style="background-color: var(--color-primary); font-size: 16px" 
                :loading="loading">
                {{ $t("actions.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "LostMyPass",
  data() {
    return {
      email: "",
      code: "",
      newPass: "",
      rules: {
        required: (value) => !!value || this.$t("messages.validation.required"),
        isEmail: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) ||
          this.$t("messages.validation.isEmail"),
        min7: (value) => !(value.length < 7) || this.$t("messages.validation.invalidCode")
      },
      step: 0,
      loading: false,
      disabled: true,
      seePassword: false,
    };
  },
  mounted() {
    this.step = 0;
    let self = this;
    window.grecaptcha.ready(function() {
      window.grecaptcha.render('grecaptcha_element_recoverPassword', {
        "sitekey" : '6LdjEbohAAAAAP2M_sTVWurGENYDYxxp_egxZ9bz',
        "callback": self.grecaptcha_response,
        "expired-callback": self.grecaptcha_response_expired,
        "error-callback": self.grecaptcha_response_expired,
        "theme": 'dark',
        "hl": self.$root.getTextlang()
      })
    })
  },
  methods: {
    sendMail: function (event) {
      event.preventDefault();
      if (!this.$refs.formRecoverPassword.validate()) {
        this.$root.swalAlert("error", this.$t("messages.validation.invalidFields"), "");
        return
      }      
      if (this.disabled) {
        this.$root.swalAlert("error", this.$t("messages.validation.ValidateReCAPTCHA"), "");
        return
      }
      this.loading = true
      this.$root.post("/Login/recoveryAccount", { 
        id_corp : this.$root.id_corp,
        email : this.email,
        server : this.$root.hostname
      }).then((response) => {
        let info = response.data
        if(info.success === 'TRUE') {
          this.step = 1
          this.disabled = true
        } else {
          this.$root.swalAlert("error", this.$t("messages.invalidUser"), "");
        }
        this.loading = false
      });
    },
    grecaptcha_response: function (token) {
      if( !token) return
      this.disabled = false;
    },
    grecaptcha_response_expired: function () {
      this.disabled = true;
    },
    verifyCode: function () {
      if (this.disabled) {
        this.$root.swalAlert("error", this.$t("messages.validation.ValidateReCAPTCHA"), "");
        return
      }
      this.loading = true
      let codeNumber = parseInt(this.code)

      this.$root.post("/Login/verifyCode", { 
        id_corp: this.$root.id_corp,
        email : this.email,
        code : codeNumber
      }).then((response) => {
        let info = response.data
        if(info.success === 'TRUE') {
          this.$root.$swal({
            title: this.$t("messages.correctCode"),
            text: null,
            icon: "success",
            confirmButtonColor: "primary",
            confirmButtonText: this.$t("actions.next"),
            allowOutsideClick: () => {
              return false
            }
          });
        // this.$root.swalAlert("success", this.$t("messages.correctCode"), "");
          this.step = 2;
        } else {
          this.$root.swalAlert("error", this.$t("messages.validation.invalidCode"), "");
        }
        this.loading = false
      });
    },
    newPassword: function (event) {
      event.preventDefault();
      if (!this.$refs.formNewPassword.validate()) {
        this.$root.swalAlert("error", this.$t("messages.validation.invalidFields"), "");
        return
      }

      this.loading = true
      this.$root.post("/Login/updatePass", { 
        id_corp: this.$root.id_corp,
        email : this.email,
        pass : this.newPass
      }).then((response) => {
        let info = response.data
        if(info.success === 'TRUE') {
          this.$root.swalAlert("success", this.$t("messages.updatedPass") , "");
          this.step = 0
          this.code = ""
          this.email = ""
          this.newPassword = ""
          this.$emit('changeView', 'showLogin')
        } else {
          this.$root.swalAlert("error", "Error", info);
        }
        this.loading = false
      });
    }
  }
};
</script>

<style scoped>
.pointman:hover {
  cursor: pointer;
}
</style>
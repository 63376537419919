<template>
  <div>
    <v-card-title>
      <div style="font-size: 26px !important; color: white;">
        <strong>
          {{ $t("actions.signIn") }}
        </strong>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" align="left" style="font-size: 15px; color:white;">
          {{ $t("login.doYouHaveAccount") }}
          <strong
            style="color: var(--color-primary)"
            class="pointman"
            @click="$emit('changeView', 'showLogin')"
          >
            {{ $t("actions.logIn") }}
          </strong>
        </v-col>
        <v-form ref="NewUserForm" class="pa-2">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0">
              <v-text-field
                dark
                background-color="#1c1c1c99"
                color="var(--color-primary)"
                v-model="user.email"
                outlined
                :rules="[rules.required, rules.isEmail]"
                v-on:keyup.enter="SaveUser"
                :label="$t('login.email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 mb-0">
              <v-text-field
                dark
                background-color="#1c1c1c99"
                color="var(--color-primary)"
                v-model="user.password"
                outlined
                :append-icon="
                  seePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                @click:append="seePassword = !seePassword"
                :rules="[rules.required]"
                v-on:keyup.enter="SaveUser"
                :type="seePassword ? 'text' : 'password'"
                :label="$t('login.password')"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" class="pb-0 mb-0">
              <v-text-field
                dark
                background-color="#1c1c1c99"
                color="var(--color-primary)"
                v-model="user.name"
                outlined
                :rules="[rules.required]"
                v-on:keyup.enter="SaveUser"
                :label="$t('login.name')"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" class="pb-0 mb-0">
              <v-text-field
                dark
                background-color="#1c1c1c99"
                color="var(--color-primary)"
                outlined
                :rules="[rules.required]"
                v-on:keyup.enter="SaveUser"
                :label="$t('login.lastName')"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" class="pb-0 mb-0">
              <v-text-field
                dark
                background-color="#1c1c1c99"
                color="var(--color-primary)"
                v-model="user.phone"
                outlined
                v-mask="'### ## ## ###'"
                :rules="[rules.required, rules.isPhone]"
                v-on:keyup.enter="SaveUser"
                :label="$t('login.phone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" align="center" class="pt-0 mt-0">
              <div id="grecaptcha_element_singup"></div>
            </v-col>
            <v-col cols="12" align="left" class="pb-0 mb-0">
              <v-checkbox
                dark
                color="var(--color-primary)"
                style="margin-top: -20px"
                v-model="user.check"
                :label="$t('login.letMeKnow')"
                hide-details
              ></v-checkbox>
              <div style="color:white;">
                {{ $t("login.letMeKnowMessage") }}
              </div>
            </v-col>
          </v-row>
          <v-col cols="12" align="center"> 
            <v-btn depressed dark style="background-color: var(--color-primary); color: white;" @click="SaveUser" 
              :disabled="disabled" :loading="loadingSignUp">
              {{ $t("actions.next") }}
            </v-btn>
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { USER_DATA } from '@/mixins/user-mixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "FromNewUser",
  mixins: [USER_DATA],
  data() {
    return {
      user: {
        email: "",
        password: "",
        name: "",
        lastName: "",
        country: "",
        postalCode: "",
        check: false,
        phone : ""
      },
      states: [],
      seePassword: false,
      disabled: true,
      rules: {
        required: (value) => !!value || this.$t("messages.validation.required"),
        isEmail: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) ||
          this.$t("messages.validation.isEmail"),
        isPhone: (value) =>
          /[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{3}/.test(value) ||
          "El número de teléfono debe ser válido",
      },
      loadingSignUp: false
    };
  },
  mounted() {
    let self = this;
    window.grecaptcha.ready(function() {
      window.grecaptcha.render('grecaptcha_element_singup', {
        "sitekey" : '6LdjEbohAAAAAP2M_sTVWurGENYDYxxp_egxZ9bz',
        "callback": self.grecaptcha_response,
        "expired-callback": self.grecaptcha_response_expired,
        "error-callback": self.grecaptcha_response_expired,
        "theme": 'dark',
        "hl": self.$root.getTextlang()
      })
    })
  },
  methods: {    
    ...mapActions({
      loginAuth: 'auth/login'
    }),
    ...mapGetters({
      getUserS: 'auth/getUserS'
    }),
    SaveUser() {
      if (!this.$refs.NewUserForm.validate()) {
        this.$root.swalAlert("error", this.$t("messages.validation.invalidFields"), "");
        return
      }      
      if (this.disabled) {
        this.$root.swalAlert("error", this.$t("messages.validation.ValidateReCAPTCHA"), "");
        return
      }
      this.loadingSignUp = true
      let self = this;
      this.$root.post("/Login/SignUpNormal", { 
        id_corp: this.$root.id_corp,
        email : this.user.email.trim(), 
        pass : this.user.password.trim(), 
        name : this.user.name.trim(), 
        lastname : this.user.lastName.trim(),
        phone: this.user.phone,
        login_type: "n"
      }).then((response) => {
        let infoSignUp = response.data;
        if (infoSignUp.success == "TRUE") {            
          this.loginAuth(infoSignUp.userInfo)
          let  userData = this.getUserS()
          this.$root.token = userData?.token_cliente
          self.getUserDataMix().then(response => {
            self.$emit("BackHome");
          });
          this.$root.Toast("success", this.$t("messages.welcome"), infoSignUp.userInfo.users);
        } else {
          if(infoSignUp.existsFlag) {
              this.$root.swalAlert(
              "error",
              this.$t("messages.existingUser")
            );
            return false;
          } else{
            this.$swal({
              icon: "error",
              title: this.$t("messages.failedToLogin"),
              text: infoSignUp.message,
            }); 
          }
        }
      })
      .finally(() => {
        this.loadingSignUp = false
      });
    },
    grecaptcha_response: function (token) {
      if( !token) return
      this.disabled = false;
    },
    grecaptcha_response_expired: function () {
      this.disabled = true;
    }
  }
};
</script>

<style scoped>
.pointman:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>
export const auth = {
  namespaced: true,
  state: {
    user: null,
    userData: null
  },
  actions: {
    login({ commit }, user) {
      commit("loginSuccess", user);
    },
    logout({ commit }) {
      commit("logout");
    },
    updateUserInfo({commit}, userData) {
      commit("updateUserInfo", userData)
    },
    initUserInfo({ commit }) {
      commit("initUserInfo")
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user))
    },
    logout(state) {
      state.user = null;
      state.userData = null;
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
    },
    updateUserInfo(state, userDataX) {
      localStorage.setItem('userData', JSON.stringify(userDataX))
      state.userData = JSON.parse(localStorage.getItem('userData'))
    },
    initUserInfo(state) {
      state.user = JSON.parse(localStorage.getItem('user'))
    }
  },
  getters: {
    getUserS (state) {
      return state.user
    },
    getUserDataS (state) {
      return state.userData
    }
  }
};

import { mapGetters } from "vuex";

export const USER_DATA = {
  data() {
    return {
      form: null
    }
  },
  methods: {
    ...mapGetters({
      getUserDataS: 'auth/getUserDataS'
    }),
    getUserDataMix: function () {
      return new Promise((resolve, reject) => {
        const user = this.$store.state.auth.userData;
        if (!this.$root.token) {
          // localStorage.setItem('redirectPath', this.$route.fullPath);
          // this.$root.goLogin()
          this.$root.showlogin = true;
          reject({ success: 'FALSE', message: this.$t("messages.TokenInvalid") })
          return false;
        }
        if (!user) {
          try {
            this.$root.post("/ServiceClient/GetUserData")
            .then((response) => {
              let info = response.data;
              if (info.success == "TRUE") {
                this.$store.dispatch('auth/updateUserInfo', info.data)
                this.form = this.getUserDataS()
                resolve(info.data)
              } else {
                this.form = null
                this.$store.dispatch('auth/updateUserInfo', null)
                // localStorage.setItem('redirectPath', this.$route.fullPath);
                // this.$root.goLogin()
                this.$root.showlogin = true;
                this.$root.loading = false
                reject(info)
              }
            })
            .catch((e) => {
              this.form = null
              this.$store.dispatch('auth/updateUserInfo', null)
              reject(e)
            })
            .finally(() => {
              this.$root.loading = false;
            });
          } catch(e) {
            reject(e)
          }
        } else {
          resolve(user)
        }
      })
    }
  }
}
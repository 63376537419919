import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// --------------- Estilos ------------------------
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'animate.css';


// --------------- Componentes --------------------
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueHtml2Canvas from 'vue-html2canvas';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { VueMaskDirective } from 'v-mask'
import VueSession from 'vue-session'


// --------------- languages --------------------
import MX from './i18n/Spanish/index.js'
import US from './i18n/English/index.js'
import FR from './i18n/French/index.js'
import './utils/global/components'
import './utils/global/elements'

Vue.use(VueSession)
Vue.use(VueI18n)
Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.use(VueHtml2Canvas);
Vue.use(Vuetify)
Vue.directive('mask', VueMaskDirective);

const i18n = new VueI18n({
  locale: 'mx',
  messages: {
    mx: MX,
    us: US,
    fr:FR,
  }
})

const opts = {}
let myTimeOut;

export default new Vuetify(opts)

Vue.config.productionTip = false

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import './utils/numberToLetter'
let QRCode = require('qrcode')

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  i18n,
  data: {
    maxwidth:'1500px',
    maxwidth2:'1300px',
    loading: false,
    showlogin: false,
    token: null,
    //myserver:'//gpstuuxan.com',
    myserver: '//t2pdevelopment.com',
    // myserver: '//192.168.1.68',
    // myserver: '//localhost',
    pathevens: '/axess/event/',
    UrlImg: "/axess/place/",
    UrlGallery: "/axess/gallery/",
    UrlCorpImages: "/axess/corpImages/",
    myport: '9888',
    myport1: "9888", // http
    myport2: "9889", // https
    langs: [
      { text: "Español", locale: "mx", abbreviation: 'ES' },
      { text: "English", locale: "us", abbreviation: 'EN' },
      // { text: "Français", locale: "fr", abbreviation: 'FR' },
    ],
    master: {},
    hostname: '',
    showDialogTicket:false,
    id_corp: false,
    logo: '',
    logo_white: '',
    timelimit: 270,
    value: 0,
    value2: 0,
    pdfodc : '',
    pdfTitles: [
      {
        "ES": "COMPROBANTE DE PAGO",
        "EN": "PROOF OF PAYMENT"
      },
      {
        "ES": "BOLETOS",
        "EN": "TICKETS"
      }
    ],
    pdfCurrentTitle: "COMPROBANTE DE PAGO",
    dpfdialog:false,
    type:null,
    vEntorno:null,
    pdfaux:[
      {caja: 0,
        codebar: "111111111111111111111",
        descripcion: "A",
        expedido: "1",
        id_event: 1,
        id_sell: 1,
        id_ticket: 1,
        id_transac: 1,
        name: "A",
        njournalno: "1",
        ntrans: "1",
        price: "0.00",
        shortname: null,
        sitio: "A",
        time: "00:00:00"},
    ],
    showWarningDialog: false,
    idsUsed: [],
    showAlertOnPdf: false
  },
  created() {
    this.getuser()
    if (window.location.protocol == "https:") {
      this.UrlImg = "https:" + this.myserver + this.UrlImg
      this.pathevens = "https:" + this.myserver + this.pathevens
      this.UrlGallery = "https:" + this.myserver + this.UrlGallery
      this.UrlCorpImages = "https:" + this.myserver + this.UrlCorpImages
      this.myserver = "https:" + this.myserver + ':';
      this.myport = this.myport2;
    } else {
      this.UrlImg = "http:" + this.myserver + this.UrlImg
      this.pathevens = "http:" + this.myserver + this.pathevens
      this.UrlGallery = "http:" + this.myserver + this.UrlGallery
      this.UrlCorpImages = "http:" + this.myserver + this.UrlCorpImages
      this.myserver = "http:" + this.myserver + ':';
      this.myport = this.myport1;
    }
  },
  methods: {
    getuser(){
      if(!!this.$cookies.get('rememberme')) {
        this.$store.dispatch('auth/initUserInfo')
        let user = this.$store.getters['auth/getUserS']
        this.$root.token = user?.token_cliente
      } else {  
        this.$store.dispatch('auth/logout')
        this.$root.token = null;
      }
    },
    goHome(){
      if (this.$route.name != 'HomePage') {
        this.$router.push({ name: "HomePage" });
      }
    },
    goLogin() {
      if (this.$route.name != 'loginPage') {
        this.$router.replace({ 
          name: 'loginPage'
        });
      }
    },
    redirectPath() {
      const redirect = localStorage.getItem('redirectPath');
      // console.log(redirect)
      if (redirect) {
        this.$router.push(redirect);
        localStorage.removeItem('redirectPath');
      } else {
        this.goHome()
      }
    },
    resetPdf() {
      let doc = new jsPDF()
      doc.text(45, 23, "")
    },
    async proofPayment(data, fistLoad) {
      try {
        let doc = new jsPDF()
        let ventas = data.ventas
  
        doc.setFontSize(11)
        doc.text(15, 23, document.title)
        doc.setFontSize(10)
        doc.text(15, 38, "CLAVE - " + data.clave)
        
        doc.setFontSize(14)
        doc.text(122, 25, 'COMPROBANTE DE PAGO')
        doc.setFontSize(10)
        doc.text(123, 33, 'FECHA DE EXPEDICIÓN: ' + data.date_expe)
        doc.text(125.6, 38, `HORA DE EXPEDICIÓN: ${data.time_expe} HRS`)
        
        // --------------- BODY ---------------       
        let sells = [], totalTickets = 0
        for(let i = 0; i < ventas.length; i++) {
          totalTickets += parseInt(ventas[i].cantidad)
          sells = [
            ...sells,
            [
              ventas[i].eventname, ventas[i].alias,
              {
                content: ventas[i].cantidad,
                colSpan: 0,
                styles: {
                  halign: 'center'
                }
              },
              {
                content: ventas[i].title ? ventas[i].title : "-",
                colSpan: 0,
                styles: {
                  halign: 'center'
                }
              },
              {
                content: ventas[i].section ? ventas[i].section : "-",
                colSpan: 0,
                styles: {
                  halign: 'center'
                }
              },
              {
                content: "$" + (ventas[i].unit_price).toFixed(2),
                colSpan: 0,
                styles: {
                  halign: 'right'
                }
              },
              {
                content: "$" + (ventas[i].subtotal).toFixed(2),
                colSpan: 0,
                styles: {
                  halign: 'right'
                }
              }
            ]          
          ]
        }

        sells = [
          ...sells,
          [
            {
              content: 'CANTIDAD TOTAL DE BOLETOS:',
              colSpan: 2,
              styles: {
                fontStyle: 'bold',
                fillColor:[240, 240, 240],
                halign: 'right'
              }
            },
            {
              content: totalTickets,
              colSpan: 0,
              styles: {
                fontStyle: 'bold',
                fillColor:[240, 240, 240],
                halign: 'center'
              }
            },
            {
              content: "",
              colSpan: 4,
              styles: {
                fillColor:[240, 240, 240],
              }
            }
          ],
          [
            {
              content: 'SUBTOTAL:',
              colSpan: 6,
              styles: {
                fontStyle: 'bold',
                fillColor:[235, 235, 235],
                halign: 'right'
              }
            },
            {
              content: "$"+Number(data.bruto).toFixed(2) + " MXN",
              colSpan: 0,
              styles: {
                fontStyle: 'bold',
                fillColor:[235, 235, 235],
                halign: 'right'
              }
            }
          ],
          [
            
            {
              content: `IVA (${(data.rate * 100)}%)`,
              colSpan: 6,
              styles: {
                fontStyle: 'bold',
                fillColor:[235, 235, 235],
                halign: 'right'
              }
            },
            {
              content: `$${Number(data.iva).toFixed(2)} MXN`,
              colSpan: 0,
              styles: {
                fontStyle: 'bold',
                fillColor:[235, 235, 235],
                halign: 'right'
              }
            }
          ],
          [
            {
              content: 'TOTAL:',
              colSpan: 6,
              styles: {
                fontStyle: 'bold',
                fillColor:[230, 230, 230],
                halign: 'right'
              }
            },
            {
              content: `$${Number(data.total).toFixed(2)} MXN`,
              colSpan: 0,
              styles: {
                fontStyle: 'bold',
                fillColor:[230, 230, 230],
                halign: 'right'
              }
            }
          ],
          [
            {
              content: "SON: " + this.$getTotalInletters(data.total) + " MXN",
              colSpan: 7,
              styles: {
                fontStyle: 'bold',
                fillColor:[215, 215, 215],
                halign: 'right'
              }
            }
          ]
        ]
        autoTable(doc, {
          theme: 'grid',
          headStyles: {
            fillColor: [0,0,0]
          },
          startY: 50,
          margin: {
            right: 19,
            left: 12.5,
            bottom: 25
          },
          tableWidth: 200,
          styles: {
            overflow: 'linebreak',
            cellWidth: 'wrap',
            halign: 'center',
            valign: 'middle',
            fontSize: 8
          },
          columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 40 },
            2: { cellWidth: 17 },
            3: { cellWidth: 21 },
            4: { cellWidth: 25 },
            5: { cellWidth: 18 },
            6: { cellWidth: 24 }
          },
          head: [['Evento', 'Ticket', 'Cantidad', 'Asiento', 'Sección', 'Precio Unitario (sin IVA)', 'Subtotal (sin IVA)']],
          body: sells,
        })
        if(!fistLoad) this.$root.dpfdialog = true
  
        const pageCount = doc.internal.getNumberOfPages()

        const { width, height } =  doc.internal.pageSize
        for (var i = 1; i <= pageCount; i++) {
          doc.setFont('helvetica', 'italic')
          doc.setFontSize(8)
          doc.setPage(i)
          doc.text('Page ' + String(i) + ' of ' + String(pageCount), width / 2, height - 20, {
            align: 'center'
          })
          doc.setFont('helvetica', 'bold')
          doc.text('El pago se ha realizado via NETPAY.', width - 10, height - 20, {
            align: 'right'
          })
          doc.text('Gracias por su compra.', width - 10, height - 15, {
            align: 'right'
          })
        }
        this.pdfodc = doc.output('bloburl')
      } catch(e) {
        this.$root.swalAlert("error", e.message);
      }
    },

    async pdf(registro, fistLoad){      
      var qr, segs

      const doc = new jsPDF('l', 'pt', 'letter');
      doc.setLineDash([6, 5], 0);
      let yy =60
      let xx = 40
      let cont = 0

      var x = 0
      while (x < registro.length) {
        if(x > 0){
          if(x % 9 == 0){
            yy =60
            xx = 40
            cont = 0
            doc.addPage();
            doc.setLineDash([6, 5], 0);
          }
        }

        let posy
        let xx2
        switch(cont){
          case 0:
            posy = yy;
            //horizontal
            doc.line(20, 205, 770, 205);    //h1
            doc.line(20, 389, 770, 389);    //h2      
            
            //vertical
            doc.line(270, 15, 270, 580);    //v1
            doc.line(520, 15, 520, 580);    //v2
            break
          case 1:
            yy = yy * x
            posy = 240
            break
          case 2:
            yy = (yy * x) + 20
            posy = 420
            break
        }
        qr = await this.$root.converqr(registro[x].codebar)

        doc.setFontSize(14)
        if(registro[x].shortname){
          doc.text(registro[x].shortname, xx, posy - 19);
        }

        if(registro[x].section != null) {
          doc.setFontSize(14)
          doc.text(registro[x].sitio, xx, posy - 16);
          doc.setFontSize(9.5)
          doc.text('seccion', xx, posy+16);
          doc.setFontSize(14)
          doc.text(registro[x].section, xx, posy+29);
          doc.setFontSize(9.5)
          if(registro[x].title) {
            doc.text('asiento', xx, posy+43);
            doc.setFontSize(14)
            doc.text(registro[x].title, xx, posy+56);
            doc.setFontSize(9.5)
          }          
          doc.text('Fecha', xx, posy+70);
          doc.setFontSize(10)
          doc.text(registro[x].date_event, xx, posy+81);
          doc.setFontSize(9.5)
          doc.text('Hora', xx + 60, posy+70);
          doc.setFontSize(10)
          doc.text(registro[x].time_event+ ' HRS', xx + 60, posy+81);
          doc.setFontSize(12)
          doc.text(registro[x].name, xx, posy);        
          doc.setFontSize(9)
          doc.text("Importe: $ "+registro[x].price+" MXN", xx, posy+97);
          doc.setFontSize(8)
          doc.text("Expedido: "+registro[x].expedido, xx, posy+110);
          doc.text("Folio: "+registro[x].caja + " -"+registro[x].id_transac, xx, posy+120);
          doc.text("Hora de Impresión "+registro[x].time, xx, posy+129);
          doc.addImage(qr, 'png', xx + 130, posy+50, 90, 90);
        } else {
            if(registro[x].time_event != null) {
              doc.setFontSize(14)
              doc.text(registro[x].sitio, xx, posy - 16);
              doc.setFontSize(9.5)
              doc.text('Fecha', xx, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x].date_event, xx, posy+29);
              doc.setFontSize(9.5)
              doc.text('Hora', xx + 60, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x].time_event+ ' HRS', xx + 60, posy+29);
              doc.setFontSize(12)
              doc.text(registro[x].name, xx, posy);        
              doc.setFontSize(9)
              doc.text("Importe: $ "+registro[x].price+" MXN", xx, posy+42);
              doc.setFontSize(8)
              doc.text("Expedido: "+registro[x].expedido, xx, posy+55);
              doc.text("Folio: "+registro[x].caja + " -"+registro[x].id_transac, xx, posy+65);
              doc.text("Hora de Impresión "+registro[x].time, xx, posy+129);
              doc.addImage(qr, 'png', xx + 130, posy+50, 90, 90);
          }else{
            doc.setFontSize(16)
            doc.text(registro[x].sitio, xx, posy);
            doc.setFontSize(12)
            doc.text(registro[x].name, xx, posy+14);
            doc.text("Importe: $ "+registro[x].price+" MXN", xx, posy+28);
            doc.text("Expedido: "+registro[x].expedido, xx, posy+42);
            doc.text("Folio: "+registro[x].caja + " -"+registro[x].id_transac, xx, posy+56);
            doc.setFontSize(8)
            doc.text("Hora de Impresión "+registro[x].time, xx, posy+68);
            doc.addImage(qr, 'png', xx + 130, posy+50, 90, 90);
          }
          
        }

        if(registro[x+1]){
          xx2 = 245
          qr = await this.$root.converqr(registro[x+1].codebar)
          doc.setFontSize(14)
          if(registro[x+1].shortname){
            doc.text(registro[x+1].shortname, xx + xx2, posy - 16);
          }
          if(registro[x+1].section != null) {
            doc.setFontSize(14)
            doc.text(registro[x+1].sitio, xx + xx2, posy - 16);
            doc.setFontSize(9.5)
            doc.text('seccion', xx + xx2, posy+16);
            doc.setFontSize(14)
            doc.text(registro[x+1].section, xx + xx2, posy+29);
            doc.setFontSize(9.5)
            if(registro[x+1].title) {
              doc.text('asiento', xx + xx2, posy+43);
              doc.setFontSize(14)
              doc.text(registro[x+1].title, xx + xx2, posy+56);
              doc.setFontSize(9.5)
            }          
            doc.text('Fecha', xx + xx2, posy+70);
            doc.setFontSize(10)
            doc.text(registro[x+1].date_event, xx + xx2, posy+81);
            doc.setFontSize(9.5)
            doc.text('Hora', xx + xx2 + 60, posy+70);
            doc.setFontSize(10)
            doc.text(registro[x+1].time_event + ' HRS', xx + xx2 + 60, posy+81);
            doc.setFontSize(12)
            doc.text(registro[x+1].name, xx + xx2, posy);        
            doc.setFontSize(9)
            doc.text("Importe: $ "+registro[x+1].price+" MXN", xx + xx2, posy+97);
            doc.setFontSize(8)
            doc.text("Expedido: "+registro[x+1].expedido, xx + xx2, posy+110);
            doc.text("Folio: "+registro[x+1].caja + " -"+registro[x+1].id_transac, xx + xx2, posy+120);
            doc.text("Hora de Impresión "+registro[x+1].time, xx + xx2, posy+129);
            doc.addImage(qr, 'png', xx + 370, posy+50, 90, 90);
          } else {
            if(registro[x].time_event != null) {
              doc.setFontSize(14)
              doc.text(registro[x+1].sitio, xx + xx2, posy - 16);
              doc.setFontSize(9.5)
              doc.text('Fecha', xx + xx2, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x+1].date_event, xx + xx2, posy+29);
              doc.setFontSize(9.5)
              doc.text('Hora', xx + xx2 + 60, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x+1].time_event + ' HRS', xx + xx2 + 60, posy+29);
              doc.setFontSize(12)
              doc.text(registro[x+1].name, xx + xx2, posy);        
              doc.setFontSize(9)
              doc.text("Importe: $ "+registro[x+1].price+" MXN", xx + xx2, posy+42);
              doc.setFontSize(8)
              doc.text("Expedido: "+registro[x+1].expedido, xx + xx2, posy+55);
              doc.text("Folio: "+registro[x+1].caja + " -"+registro[x+1].id_transac, xx + xx2, posy+65);
              doc.text("Hora de Impresión "+registro[x+1].time, xx + xx2, posy+129);
              doc.addImage(qr, 'png', xx + 370, posy+50, 90, 90);
            }else{
              doc.setFontSize(16)
              doc.text(registro[x+1].sitio, xx + xx2, posy);
              doc.setFontSize(12)
              doc.text(registro[x+1].name, xx +  xx2, posy+14);
              doc.text("Importe: $ "+registro[x+1].price+" MXN", xx +  xx2, posy+28);
              doc.text("Expedido: "+registro[x+1].expedido, xx +  xx2, posy+42);
              doc.text("Folio: "+registro[x+1].caja + " -"+registro[x+1].id_transac, xx +  xx2, posy+56);
              doc.setFontSize(8)
              doc.text("Hora de Impresión "+registro[x+1].time, xx +  xx2, posy+68);
              doc.addImage(qr, 'png', xx + 370, posy+50, 90, 90);
            }
          }       
        }

        if(registro[x+2]){
          xx2 = 500
          qr = await this.$root.converqr(registro[x+2].codebar)
          doc.setFontSize(14)
          if(registro[x+2].shortname){
            doc.text(registro[x+2].shortname, xx + xx2, posy - 16);
          }
          if(registro[x+2].section != null) {
            doc.setFontSize(14)
            doc.text(registro[x+2].sitio, xx + xx2, posy - 16);
            doc.setFontSize(9.5)
            doc.text('seccion', xx + xx2, posy+16);
            doc.setFontSize(14)
            doc.text(registro[x+2].section, xx + xx2, posy+29);
            doc.setFontSize(9.5)
            if(registro[x+2].title) {
              doc.text('asiento', xx + xx2, posy+43);
              doc.setFontSize(14)
              doc.text(registro[x+2].title, xx + xx2, posy+56);
              doc.setFontSize(9.5)
            }          
            doc.text('Fecha', xx + xx2, posy+70);
            doc.setFontSize(10)
            doc.text(registro[x+2].date_event, xx + xx2, posy+81);
            doc.setFontSize(9.5)
            doc.text('Hora', xx + xx2 + 60, posy+70);
            doc.setFontSize(10)
            doc.text(registro[x+2].time_event + ' HRS', xx + xx2 + 60, posy+81);
            doc.setFontSize(12)
            doc.text(registro[x+2].name, xx + xx2, posy);        
            doc.setFontSize(9)
            doc.text("Importe: $ "+registro[x+2].price+" MXN", xx + xx2, posy+97);
            doc.setFontSize(8)
            doc.text("Expedido: "+registro[x+2].expedido, xx + xx2, posy+110);
            doc.text("Folio: "+registro[x+2].caja + " -"+registro[x+2].id_transac, xx + xx2, posy+120);
            doc.text("Hora de Impresión "+registro[x+2].time, xx + xx2, posy+129);
            doc.addImage(qr, 'png', xx + 630, posy+50, 90, 90);
          } else {
            if(registro[x+2].time_event != null) {
              doc.setFontSize(14)
              doc.text(registro[x+2].sitio, xx + xx2, posy - 16);
              doc.setFontSize(9.5)
              doc.text('Fecha', xx + xx2, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x+2].date_event, xx + xx2, posy+29);
              doc.setFontSize(9.5)
              doc.text('Hora', xx + xx2 + 60, posy+16);
              doc.setFontSize(10)
              doc.text(registro[x+2].time_event + ' HRS', xx + xx2 + 60, posy+29);
              doc.setFontSize(12)
              doc.text(registro[x+2].name, xx + xx2, posy);        
              doc.setFontSize(9)
              doc.text("Importe: $ "+registro[x+2].price+" MXN", xx + xx2, posy+42);
              doc.setFontSize(8)
              doc.text("Expedido: "+registro[x+2].expedido, xx + xx2, posy+55);
              doc.text("Folio: "+registro[x+2].caja + " -"+registro[x+2].id_transac, xx + xx2, posy+65);
              doc.text("Hora de Impresión "+registro[x+2].time, xx + xx2, posy+129);
              doc.addImage(qr, 'png', xx + 630, posy+50, 90, 90);
            }else{
              doc.setFontSize(16)
              doc.text(registro[x+2].sitio, xx + xx2, posy);
              doc.setFontSize(12)
              doc.text(registro[x+2].name, xx +  xx2, posy+14);
              doc.text("Importe: $ "+registro[x+2].price+" MXN", xx +  xx2, posy+28);
              doc.text("Expedido:"+registro[x+2].expedido, xx +  xx2, posy+42);
              doc.text("Folio: "+registro[x+2].caja + " -"+registro[x+2].id_transac, xx +  xx2, posy+56);
              doc.setFontSize(8)
              doc.text("Hora de Impresión "+registro[x+2].time, xx +  xx2, posy+68);
              doc.addImage(qr, 'png', xx + 630, posy+50, 90, 90);
            }
          }
        }
        cont += 1
        x = x + 3
      }   
      /* let mobil = await this.$root.detectmobil()
      if(mobil){
        doc.save("Tickets"+Date.now());
      }else{ */
        if(!fistLoad) this.$root.dpfdialog = true
        this.pdfodc = doc.output('bloburl')
      //}

      //window.open(doc.output('bloburl'), '_blank')
      //doc.save("a4.pdf");
    },
    converqr(segs){
      return new Promise((resolve, reject) => {
        QRCode.toDataURL(''+segs, function (err, url) {
          resolve(url);
        })
      })
    },
    getTextlang: function () {
      let nameLang = '';
      this.langs.forEach(lang => {
        if (lang.locale === this.$i18n.locale)
          nameLang = lang.abbreviation
      })
      return nameLang;
    },
    footerProps: function () {
      return {
        itemsPerPageText: this.$t('tableHeaders.rowsporpage'),
        itemsPerPageAllText: this.$t('tableHeaders.itemsPerPageAllText'),
        
        itemsPerPageOptions: [10, 30, 50, -1],
      }
    },
    Toast: function (icon, title, text) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 280,
        timer: 1000,
        timerProgressBar: true,
        icon: icon,
        title: title,
        text: text,
      })
    },
    swalAlert: function (icon, title, text) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
      });
    },
    confimDelay: function (icon, title, text, textConfirm) {
      var self = this;
      return new Promise((resolve, reject) => {
        this.$swal({
          title,
          text,
          icon,
          showDenyButton: true,
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "primary",
          confirmButtonText: textConfirm,
          denyButtonColor: "#dd8159",
          didOpen: function () {
            var b = self.$swal.getConfirmButton()
            b.disabled = true;

            var n = 5;
            for (let i = 0; i < 5; i++) {
              setTimeout(function () {
                b.textContent = textConfirm + ` (${n})`;
                n = n - 1;
              }, i * 1000);
            }

            setTimeout(function () {
              b.disabled = false;
              b.textContent = textConfirm;
            }, 5000);
          }
        }).then((result) => {
          resolve(result);
        })
          .catch((error) => {
            // console.log(error);
            reject(error);
          })
      });
    },
    Exit() {
      this.token = null;
      if (this.$route.name != 'HomePage') {
        this.$router.push({ name: "HomePage" });
      }
      this.$store.dispatch('auth/logout')
      this.$cookies.remove("rememberme");      
      this.$store.dispatch('shopping/resetRegularSell')
      this.$store.dispatch('shopping/resetCrossedSell')
    },
    post: function (endPoint, dataX) {
      // let token = JSON.parse(localStorage.getItem("user"))
      let token = this.$store?.getters?.['auth/getUserS']?.token_cliente
      const data = {...dataX, token_corp: this.$root.hostname }
      if (token) {
        token = token.token_cliente
      }
      return new Promise((resolve, reject) => {
        axios.post(
            this.$root.myserver + this.$root.myport + endPoint,
            { data },
            {
              headers: {
                "X-Access-Token": this.$root.token
              }
            }
          )
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            if(error.response){
              if (error.response.status === 401) {
                // console.log(this.$store.state.sites)
                // // if(this.$route.name)
                // localStorage.setItem('redirectPath', this.$route.fullPath);
                // this.goLogin()
                this.showlogin = true
                this.swalAlert('error', this.$t("messages.invalidUser"));
              }
            }else{
              this.swalAlert('error', error.message);
            }
            reject(error);
          });
      });
    },
    get: function (endPoint) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.$root.myserver + this.$root.myport + endPoint,
            { headers: { "X-Access-Token": this.$root.token.token } }
          )
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nowtime() {
      var d = new Date();
      return d.getTime();
    },
    formatNum: function (value) {
      if (!value) {
        value = 0
      }
      if (typeof value === 'string') {
        value = parseFloat(value)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatPrice: function (value) {
      if (!value) {
        value = 0
      }
      if (typeof value === 'string') {
        value = parseFloat(value)
      }
      value = value.toFixed(2)
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    PaintFigures(polygon) {
      if (polygon) {
        return polygon
          .map((point) => {
            return point.x + "," + point.y;
          })
          .join(" ");
      }
    },
    async detectmobil() {
      let val = false
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        val = true
      }
      return val
    },
    beginTime: function () {
      myTimeOut = setTimeout(() => {
        if (this.value === this.timelimit) {
          this.$root.swalAlert(
            "error",
            "",
            this.$t('messages.timeoutExpired')
          );
          this.finish();
          return false;
        }
        this.value += 1;
        this.value2 = (100 * this.value) / this.timelimit;
        this.beginTime();
      }, 1000);
    },
    resetShoppingTime() {
      clearTimeout(myTimeOut);
    },
    removeCDN() {
      try {
        let scriptNetpay = 'script[src="https://docs.netpay.mx/cdn/js/dev/netpay.min.154.js"]'
        let script3DNet = 'script[src="https://cdn.netpay.mx/js/dev/netpay3ds.js"]'

        if(!this.$store.state.sites.sandboxNet) {
          scriptNetpay = 'script[src="https://docs.netpay.mx/cdn/v1.3/netpay.min.js"]'
          script3DNet = 'script[src="https://cdn.netpay.mx/js/latest/netpay3ds.js"]'
        }

        let script = document.querySelector(scriptNetpay);
        let script2 = document.querySelector(script3DNet);
        
        if (script) {
          script.remove();
        }
        if (script2) {
          script2.remove();
        }
        window.netpay3ds = undefined
        window.NetPay = undefined
        window.$ = undefined

        delete window.netpay3ds
        delete window.NetPay
        delete window.$
        let modal = document.getElementById("Cardinal-ElementContainer")
        if(modal) {
          modal.remove()
        }
      } catch (error) {        
        this.$root.swalAlert('error', "", error.message);
        this.$root.loading = false;
      }
    },
    finish() {
      this.removeCDN();
      clearTimeout(myTimeOut);
      this.$store.dispatch('shopping/resetRegularSell')
      this.$root.showlogin = false;
      this.$root.loading = false;
      this.$vEntorno.textloading = "Loading...";     
      this.goHome();
    },
  },
  render: h => h(App)
}).$mount('#app')

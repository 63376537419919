module.exports.navigation = {
  home: 'Maison',
  centerTickets: 'centres pasetotal',
  help: 'Aider',
  account: {
    me: 'Mon compte',
    mytickets: {
      title: 'Mes billets',
      items: ["Événements à venir", "Événements passés", "Mes billets"]
    },
    myProfile: 'Mon profil',
    Logout: 'Déconnexion'
  },
  accountData: "Données d'utilisateur",
  accountAddressData: "Adresse de l'utilisateur",
}
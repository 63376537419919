module.exports.sales = {
  typePersonTitle: "Seleccione los participantes para el evento",
  newSelec: "Nueva cantidad",
  successPaymentMessages: {
     
  },
  failurePaymentMessages: {
    startNewShopMessage: "Ocurrió un error al iniciar la compra",
    generatingTransaction: "Error al generar la transacción"
  },
  netpaySuccessMessages: {
    validateCard: '1 de 5 - Validando Tarjeta...',
    generateTransaction: '2 de 5 - Generando transacción...',
    makeCharge: '3 de 5 - Realizando cobro...',
    validationRequired: 'Se requiere validación para la tarjeta, por favor llene los datos del formulario...',
    send3dSecure: '4 de 5 - Realizando validación...',
    verifyPayment: '5 de 5 - Verificando pago y generando boletos. Espere un momento...'
  },
  netpayErrorMessages: {
    verifyCardDetails: "Favor de verificar los datos de la tarjeta",
    invalidCard: "¡Tarjeta inválida!",
    ticketsError: "Ocurrió un error al generar los boletos, por favor, contacte a soporte.",
    payError: "Ocurrió un error al realizar el pago",
    netpayError: "Ocurrió un error inesperado, por favor, refresque la página y vuelva a intentarlo",
    netpayProblem: 'Error del servicio de cobro. Inténtelo más tarde.',
  },
  serviceUnavailableMessage: "Temporalmente, este servicio no está disponible. Gracias por su comprensión."
}
import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth-module';
import { shopping } from './shopping-module';
import { sites } from './sites-module';
import { corpInfo } from './corp-module';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    shopping,
    sites,
    corpInfo
  },
})

module.exports.actions = {
  categorias:[
    {title:'', icon: 'mdi-domain' },
    {title:'Paradores Turistico', icon: 'mdi-domain' },
    {title:'Museo', icon: 'mdi-domain' },
    {title:'Evento', icon: 'mdi-domain' },
    {title:'Teatro', icon: 'mdi-domain' },
    {title:'Centro Cultural', icon: 'mdi-domain' },
    {title:'Auditorio', icon: 'mdi-domain' },
    {title:'Audiovisial', icon: 'mdi-domain' },
    {title:'Taller', icon: 'mdi-domain' },
  ],
  tickets:'Boleto',
  packets:'Paquetes',
  price:'Precio',
  amount:'Cantidad',
  place:'Sitio',
  SeeTickets:'Ver Boletos',
  logIn: 'Iniciar sesión',
  logInFacebook: 'Iniciar sesión con Facebook',
  Logout: 'Cerrar sesión',
  signIn: 'Registrarse',
  next: 'Siguiente',
  seeTickets: 'Ver boletos',
  goBack: 'Regresar',
  cancel: 'Cancelar',
  canceled: 'CANCELADO',
  save: 'Guardar',
  change: 'Cambiar',
  toClose: 'Cerrar',
  search: 'Buscar',
  printTickets: 'Imprimir boletos',
  understand: 'Entiendo',
  nextEvent:'Proximos Eventos',
  findTicket:'Buscar Boletos',
  Events:'Evento(s)',
  EventS2:'EVENTOS',
  limitedAvai:'Disponibles',
  limitedAvai2:'Agotado',
  about:'Acerca de',
  ticketFor:'Boletos para',
  limitEdad:'Límite de edad: No hay restricciones. Pagan boleto a partir de: 3 años. Restricciones: Se prohíbe el ingreso con alimentos, bebidas, cámaras de video ...',
  cardinfo:{
    tickets:'Boleto(s)',
    ticketsUp: 'BOLETO(S)',
    section:'SECCION',
    row:'FILA',
    colum:'COLUMNA',
    seat:'ASIENTO',
    unitPrice: 'PRECIO UNITARIO',
    unitPrice2: 'Precio Unitario',
    price:'PRECIO',
    total: 'TOTAL',
    subtotal: 'SUBTOTAL',
    amount:'CANTIDAD',
    numberTickets: "Número de boletos",
    dateEvent: "Fecha Evento",
    timeEvent: "Hora Evento",
  },
  step:{
    usuario:'Datos del Usuario',
    pay:'Datos del pago',
    confirma:'Confirmación de compra',
    confirma1:'CONFIRMAR',
    confirma2:'COMPRA',
  },
  paycard:{
    cardname: 'Nombre del propietario de la tarjeta',
    card:'No. Tarjeta',
    exp:'Expiración',
    requi:'Este campo es requerido',
    terminos:'Términos y condiciones',
    read:'He leído los',
  },
  pdf:{
    oops:"Oops! Tu navegador no soporta PDFs!",
    download:'Descargar Archivo'
  },
  next:'CONTINUAR',
  shop:'COMPRAR',
  resume: 'Resumen del pago',
  information: 'Infomación',
  more:'Mas Informatión',
  address: 'Domicilio',
  shoppingCart: 'Carrito',
  categories : {
    0: 'ACCESO GENERAL',
    1: 'ACTIVIDADES',
    2: 'COMBINACIONES'
  },
  addToCart: 'Añadir al carrito',
  ready: 'Listo',
  shopSelectedTickets: 'Comprar boleto(s) seleccionado(s)',
  shopDetails: 'Detalles de la compra',
  cancelShop: 'Cancelar compra',
  paymentStatus: {
    notPaid: "Rechazado",
    paid: "Pagado"
  }
}
export const sites = {
  namespaced: true,
  state: {
    events:[],
    indexEveSel:0,
    eventselect: null,
    userEvents:[],
    tickets:[],
    ticketsSection:[],
    indexEveSelSection:null,
    temp_eventSelect:null,
    showNoData: false,
    currentTicketGroup: 0,
    credential_id: null,
    apiKeyNet : null,
    sandboxNet: true,
    statistics: 0
  },
  getters: {
    getCurrentCategory: (state) => (id) => {
      return state.events[0].categories.find(({ id_category }) => id_category === id )
    }
  },
  actions: {
    chargeCurrentEvent({ commit }, event) {
      commit("chargeData", event);
    },
    selectEventDate({ commit }, dateSelected) {
      commit("selectDateEvent", dateSelected);
    },
    changeSelectedSection({ commit }, eventSelected) {
      commit("changeSelectedSection", eventSelected);
    },
    setNetpayConfig ( { commit }, accountInfo) {
      commit("setNetpayConfig", accountInfo);
    },
    setStatistics ( { commit }, statisticsValue) {
      commit("setStatistics_M", statisticsValue);
    },
    resetEventValues ( { commit } ) {
      commit("reset");
    },
    resetMapEventValues( { commit } ) {      
      commit("resetForMapValues");
    }
  },
  mutations: {
    chargeData(state, event) {
     state.events[0] = event
    },
    selectDateEvent (state, dateInfo) {
      let { eventDate, index } = dateInfo;
      state.eventselect = eventDate
      state.temp_eventSelect = index
    },
    changeSelectedSection(state, eventSelected) {
      let { section, index } = eventSelected;
      state.indexEveSelSection = index
      state.temp_eventSelect = section
    },
    reset(state) {
      state.tickets = []      
      state.indexEveSelSection = null
      state.temp_eventSelect = null
      state.eventselect = null
    },
    resetForMapValues(state) {
      state.indexEveSelSection = null
    },
    setNetpayConfig(state, accountInfo) {
      state.credential_id = accountInfo.credential_id
      state.apiKeyNet = accountInfo.apiKey
      state.sandboxNet = accountInfo.sandbox
    },
    setStatistics_M(state, statisticsValue) {
      state.statistics = statisticsValue
    }
  }
};

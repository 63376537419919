
export const corpInfo = {
  namespaced: true,
  state: {
    darkTheme: true,
    backgroundImage: null,
    footerInfo: null,
    logo: null,
    eventCategories: []
  },
  actions: {
    setCorpTheme({ commit }, theme) {
      commit("setCorpTheme_M", theme);
    },
    setCorpCategories({ commit }, categories) {
      commit("setCorpCategories_M", categories);
    }
  },
  mutations: {
    setCorpTheme_M(state, { dark_on, logo, bg_image, footerinfo }) {
      state.darkTheme = dark_on
      state.logo = logo
      state.backgroundImage = bg_image
      state.footerInfo = footerinfo
    },    
    setCorpCategories_M(state, categories) {
      state.eventCategories = categories
    }
  },
  getters: {
    getEventCategorie: (state) => (id) => {
      return state.eventCategories.find(categorie => categorie.id_eventcata === id)
    }
  }
};

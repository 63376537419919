<template>
  <div>
    <v-card-title :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
      <div style="font-size: 26px !important;">
        <strong>{{ $t("actions.logIn") }}</strong>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="loginForm">
        <v-row>
          <v-col :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]" 
            cols="12" align="left" style="font-size: 15px">
            {{ $t("login.areYouNew") }}
            <strong
              style="color: var(--color-primary)"
              class="pointman"
              @click="$emit('changeView', 'showRegister')"
            >
              {{ $t("actions.signIn") }}
            </strong>
          </v-col>
          <v-col cols="12" class="pb-0 mb-0">
            <v-text-field
              :dark="$store.state.corpInfo.darkTheme"
              :background-color="$store.state.corpInfo.darkTheme ? '#1C1C1C99':'#E0E0E099'"
              color="var(--color-primary)"
              v-model="user"
              outlined
              :label="$t('login.email')"
              :rules="[rules.required, rules.isEmail]"
              v-on:keyup.enter="login"
              type="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-0 mb-0">
            <v-text-field
              :dark="$store.state.corpInfo.darkTheme"
              :background-color="$store.state.corpInfo.darkTheme ? '#1C1C1C99':'#E0E0E099'"
              color="var(--color-primary)"
              v-model="pass"
              outlined
              :append-icon="
                seePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="seePassword = !seePassword"
              v-on:keyup.enter="login"
              :rules="[rules.required]"
              :type="seePassword ? 'text' : 'password'"
              :label="$t('login.password')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" align="center" class="pt-0 mt-0">
            <div id="grecaptcha_element"></div>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <v-checkbox
              class="pt-0 mt-0"
              :dark="$store.state.corpInfo.darkTheme"
              color="var(--color-primary)"
              v-model="check"
              :label="$t('login.rememberMe')"
              hide-details
            ></v-checkbox>
            <div
              @click="$emit('changeView', 'showLostPass')"
            >
              <strong style="color: var(--color-primary)" class="pointman">
                {{ $t("login.forgotPassword") }}
              </strong>
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              depressed
              :dark="$store.state.corpInfo.darkTheme"
              class="facebook-button"
              @click="login()"
              style="
                background-color: var(--color-primary);
                min-width: 225px;
              "
              :disabled="disabled"
              :loading="loading"
            >
              {{ $t("actions.logIn") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="mt-0 pa-0 mx-0" v-if="false">
            <v-divider dark></v-divider>
          </v-col>

          <v-col cols="12" class="mb-0 pb-0" v-if="false">
            <v-btn
              color="#3b5998"
              class="facebook-button"
              id="facebookSignin"
              dark
              @click="loginFB"
            >
              <v-icon class="mr-4"> mdi-facebook </v-icon>
              {{ $t("actions.logInFacebook") }}
            </v-btn>
          </v-col>

          <v-col cols="12" v-if="false">
            <div id="googleSignin" style="min-height: 40px">
              <v-progress-circular indeterminate color="white"></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { USER_DATA } from '@/mixins/user-mixin'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FormLogin",
  mixins: [USER_DATA],
  data() {
    return {
      user: "",
      pass: "",
      check: null,
      loginOptions: { scope: "email" },
      FB: undefined,
      disabled: true, 
      isConnected: false,
      seePassword: false,
      rules: {
        required: (value) => !!value || this.$t("messages.validation.required"),
        isEmail: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) ||
          this.$t("messages.validation.isEmail"),
      },
      loading: false
    };
  },
  // async beforeMount() {
  //   let self = this;
    
  //   await this.loadFacebookSDK(document, "script", "facebook-jssdk");
  //   await this.initFacebook();
  //   window.google.accounts.id.initialize({
  //     client_id:
  //       "9048021095-o6noiq3mtvh6gndc8a1lmagd6ibafept.apps.googleusercontent.com",
  //     callback: self.googleSignIn,
  //   });
  // },
  mounted() {
    let self = this;
    window.grecaptcha.ready(function() {
      window.grecaptcha.render('grecaptcha_element', {
        "sitekey" : '6LdjEbohAAAAAP2M_sTVWurGENYDYxxp_egxZ9bz',
        "callback": self.grecaptcha_response,
        "expired-callback": self.grecaptcha_response_expired,
        "error-callback": self.grecaptcha_response_expired,
        "theme": 'dark',
        "hl": self.$root.getTextlang()
      })
    })
    // setTimeout(() => {
    //   this.renderButtonGoogle()
    // }, 200);
  },
  methods: {
    ...mapActions({
      loginAuth: 'auth/login'
    }),
    ...mapGetters({
      getUserS: 'auth/getUserS'
    }),
    login: function () {
      if (!this.$refs.loginForm.validate()) {
        this.$root.swalAlert("error", this.$t("messages.validation.invalidFields"), "");
        return
      }
      if (this.disabled) {
        this.$root.swalAlert("error", this.$t("messages.validation.ValidateReCAPTCHA"), "");
        return
      }
      this.loading = true
      this.$root
      .post("/Login/user", { email: this.user, password: this.pass, id_corp: this.$root.id_corp, login_type:'n' })
      .then((response) => {
        let info = response.data;
        if (info.success == "TRUE") {
          this.loginAuth(info.Data[0])
          let userData = this.getUserS()
          this.$root.token = userData?.token_cliente

          if (this.check) this.$cookies.set('rememberme', true);
          else this.$cookies.remove("rememberme");

          this.getUserDataMix().then(response => {
            this.$emit("BackHome");
          });
          this.$root.Toast("success", this.$t("messages.welcome"), "");
        } else {
          this.$root.swalAlert(
            "error",
            this.$t("messages.validation.InvalidEmailPassword")
          );
        }
      })
      .finally(() => {          
        this.loading = false
      });
    },
    signUp(email, pass, name) {
      this.$root
        .post("/Login/user", { id_corp : this.$root.id_corp, email: email, password: pass })
        .then((response) => {
          let info = response.data;
          if (info.success == "TRUE") {
            this.loginAuth(info.Data[0])
            let  userData = this.getUserS()
            this.$root.token = userData?.token_cliente
            this.getUserDataMix().then(response => {
              this.$emit("BackHome");
            });
            this.$root.Toast("success", this.$t("messages.invalidUser"), "");
          }
        });
    },
    signUpFacebook: function (email, pass, name, last_name, login_type) {
      this.$root
        .post("/Login/SignUpWithFacebook", {
          id_corp : this.$root.id_corp,
          email,
          pass,
          name,
          last_name,
          login_type
        })
        .then((response) => {
          let infoSignUp = response.data;
          if (infoSignUp.success == "TRUE") {
            
            this.loginAuth(infoSignUp.userInfo)
            let  userData = this.getUserS()
            this.$root.token = userData?.token_cliente
            this.getUserDataMix().then(response => {
              this.$emit("BackHome");
            });
            this.$root.Toast("success", this.$t("messages.welcome"), "");
          } else {
            this.$swal({
              icon: "error",
              title: this.$t("messages.failedToLogin"),
              text: infoSignUp.message,
            });
          }
        });
    },
    getUserData() {
      var self = this;
      window.FB.api(
        "/me",
        {
          fields:"id,email,first_name,gender,last_name,link,locale,timezone,updated_time,verified",
        },
        (userInformation) => {
          if (!userInformation.email) {
            self
              .$swal({
                title: self.$t("messages.permissionsRequired"),
                text: self.$t("messages.needEmailMsg"),
                icon: "warning",
                showDenyButton: true,
                showCancelButton: false,
                reverseButtons: true,
                confirmButtonColor: "primary",
                confirmButtonText: self.$t("messages.tryAgain"),
                denyButtonColor: "#dd8159",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  self.rerequest();
                }
              });
            return;
          }
          self.signUpFacebook(
            userInformation.email,
            userInformation.id,
            userInformation.first_name,
            userInformation.last_name,
            'f'
          );
        }
      );
    },
    rerequest: function () {
      let self = this;
      window.FB.login(
        function (response) {
          if (response.status == "connected") {
            self.getUserData();
          } else {
            self.$swal({
              icon: "error",
              title: self.$t("messages.failedToLogin"),
            });
          }
        },
        {
          scope: "email",
          return_scopes: true,
          auth_type: "rerequest",
        }
      );
    },
    loginFB: async function () {
      let self = this
      window.FB.login(
        function (response) {
          if (response.status == "connected") {
            self.getUserData();
          } else {
            self.$swal({
              icon: "error",
              title: self.$t("messages.failedToLogin"),
            });
          }
        },
        {
          scope: "email",
          return_scopes: true,
      });
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: '1339446906817582',
          cookie: true,
          xfbml: true,
          version: 'v15.0',
        });
        window.FB.AppEvents.logPageView();
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs)
    },
    renderButtonGoogle: function () {
      let self = this;
      // Display the Sign In With Google Button
      let btnFacebook = document.getElementById("facebookSignin");
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignin"),
        {
          width: btnFacebook.clientWidth,
          text: "signup_with",
          logo_alignment: "center",
          hl: self.$root.getTextlang()
        }
      );

      // Display the One Tap prompt
      window.google.accounts.id.prompt();
    },  
    googleSignIn(googleUser) {
      if (!googleUser.credential) {
        self.$swal({
          icon: "error",
          title: self.$t("messages.failedToLogin"),
        });
        return;
      }
      let token = googleUser.credential;
      let decoded = jwt_decode(token);
      this.signUpFacebook(
        decoded.email,
        decoded.sub,
        decoded.given_name,
        decoded.family_name,
        'g'
      );
    },
    grecaptcha_response: function (token) {
      if( !token) return
      this.disabled = false;
    },
    grecaptcha_response_expired: function () {
      this.disabled = true;
    }
  },
};
</script>

<style scoped>
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 9999s ease-in-out 0s;
} */
.facebook-button {
  display: block;
  margin: 0px auto;
  width: 100%;
  max-width: 400px;
  height: 57px;
}

#googleSignin {
  display: flex !important;
  justify-content: center !important;
  color: black !important;
}

.pointman:hover {
  cursor: pointer;
  opacity: 0.7;
}
.MyBackground {
  background: rgb(231, 85, 27);
  background: linear-gradient(
    207deg,
    rgba(231, 85, 27, 1) 0%,
    rgba(241, 181, 75, 1) 45%,
    rgba(206, 123, 68, 1) 99%
  );
}
.Remember label {
  top: 6px !important;
}
</style>
<template>
  <v-app
    id="app"
    class="noselect"
  >
    <Loading
      class="MyLoading"
      v-if="$root.loading"
      style="background: #1c1c1c99 !important"
    ></Loading>
    <div v-if="$root.showlogin" class="login">
      <Login/>
    </div>
    
    <pdfview v-if="$root.dpfdialog"/>     
    <WarningInfoDialog :showDialog="$root.showWarningDialog"/>
    <Header ref="HeaderX" 
      class="header-style" 
      v-if="$route.name != 'sell' && 
        $route.name != 'eventPreview' && 
        $route.name != 'events' && 
        $route.name != 'loginPage'"
    />
    
    <div class="body-page-container" :class="[{
      'full-size': ($route.name != 'sell' || $route.name != 'eventPreview' || $route.name != 'events' || $route.name != 'loginPage')
    }]">
      <router-view/>
  
      <Footer id="MyFooterCool"
        v-if="$route.name != 'sell' && 
        $route.name != 'eventPreview' && 
        $route.name != 'events' && 
        $route.name != 'loginPage'"
      />
    </div>
   
  </v-app>
</template>

<script>
import WarningInfoDialog from './components/Tools/WarningInfoDialog.vue';
import Loading from "./components/Tools/LoadingAnimation";
import Login from "./components/Login/login.vue"
import pdfview from './components/Tools/PdfView.vue'
import Header from './components/Header.vue';
import Footer from './components/FooterX2.vue';
import $ from "jquery";

export default {
  components: {
    Loading,
    Login,
    pdfview,
    WarningInfoDialog,
    Header,
    Footer
  },
  data() {
    return {
      
    };
  },
  created(){
    // console.log("%cDetente!!","color:red;font-size: 24px;font-weight: bold;");
    // console.log('%cEsta función del navegador está pensada para desarrolladores. Si alguien te ha indicado que copiaras y pegaras algo aquí eliminaremos tu cuenta, o procederemos legalmente ya que se trata de un fraude.',"font-size: 18px;")
    if(window.location.hostname == 'cultur.ticket2pass.com') {
      this.$root.showWarningDialog = true;
    }
  },
  async beforeMount() {
    this.$root.getuser()
    if(!this.$cookies.isKey('uploadedPdf')) {
        let fakeTicket = [
          {
            caja : 0,
            codebar : 12345678,
            descripcion: 'Boleto de precarga sin valor',
            expedido : "00/00/2000",
            id_event : 0,
            id_sell : 0,
            id_ticket : 0,
            id_transac : 0,
            name : "Fake",
            njournalno : "0000",
            ntrans : "000",
            price : "00.00",
            shortname : null,
            sitio : "Fake",
            time :"00:00:00"
          }
        ]
        await this.$root.pdf(fakeTicket, true)
        this.$cookies.set('uploadedPdf', true);
      }
      
    this.setColorApp()
    this.$root.detectmobil().then(mobil => {
      if(!mobil){
        $("html").css({ overflow: "hidden" });
      }
    })
  },
  methods: {
    setColorApp: function() {
      document.documentElement.style.setProperty("--color-black", "#212121");
      document.documentElement.style.setProperty("--azul-fuerte", "#41609d");
      document.documentElement.style.setProperty("--black", "#000");
      document.documentElement.style.setProperty("--white", "#ebebeb");

      this.$root.hostname = window.location.hostname || 'pasetotal.ticket2pass.com'

      this.$root.post("/ServiceClient/LoadThemes",{
        server: this.$root.hostname
      }).then((response) => {
        let info = response.data;
        if(info.success === "TRUE") {
          info = info.Data[0] || info.Data;
          this.$root.id_corp = info.id_corp
          this.$store.dispatch('sites/setNetpayConfig', { 
            credential_id: info.credential_id, 
            apiKey: info.api_key, sandbox: info.sandbox 
          })
          if(info.statistics !== 0) {
            this.$store.dispatch('sites/setStatistics', info.statistics)
            this.getStadisticsValues()
          }
          let colors = info.colors
          for(var name in colors){
            document.documentElement.style.setProperty(name, colors[name]);
          }
          this.$store.dispatch('corpInfo/setCorpTheme', {
            dark_on: info.dark_on,
            logo: info.logo,
            bg_image: info.bg_image,
            footerinfo: info.footerinfo
          })
          document.title = info.name ? info.name : 'TICKET2PASS';
          const head = document.querySelector('head');
          const iconLink = head.querySelector("[rel='icon']") || document.createElement('link');

          iconLink.rel = 'icon';
          // iconLink.type = 'image/png';
          iconLink.href = this.$root.UrlCorpImages + info.logo; // Reemplaza con la nueva ruta del icono

          if (!head.querySelector("[rel='icon']")) {
            head.appendChild(iconLink);
          }
        }
      }).catch(e => e)
      .finally(() => {
        this.$root.loading = false;
      })
    },
    getStadisticsValues() {
      this.$root.post("/ServiceClient/loadStadisticsValues",{
        id_corp: this.$root.id_corp
      }).then((response) => {
        let info = response.data;
        if(info.success == "TRUE") {
          this.$store.dispatch('shopping/setStadisticsValue', info.Data)
        }
      }).catch(e => e)
    }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.header-style {
  height: 72px;
}
.body-page-container {
  height: calc(100vh - 72px);
  overflow: auto;
}
.full-size {
  height: 100vh !important;
}
.MyLoading {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  height: 100 vh;
  width: 100 vw;
}
.login{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: #e5e5e5;
  background: linear-gradient(
    165deg,
    var(--color-primary-light1)  0%,
    var(--color-primary-light) 40%,
    var(--color-primary-light) 45%,
    var(--color-primary)  93%
  )
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary) !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-hover) !important;
  cursor: pointer !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
</style>

module.exports.sales = {
  typePersonTitle: "Select Participants for the Event",
  newSelec: "New quantity",
  successPaymentMessages: {
     
  },
  failurePaymentMessages: {
    startNewShopMessage: "An error occurred while purchasing",
    generatingTransaction: "Error generating transaction"
  },
  netpaySuccessMessages: {
    validateCard: '1 of 5 - Validating card...',
    generateTransaction: '2 of 5 - Generating transaction...',
    makeCharge: '3 of 5 - Making payment...',
    validationRequired: 'Card validation is required, please fill out the form details...',
    send3dSecure: '4 of 5 - Performing validation...',
    verifyPayment: '5 of 5 - Verifying payment and generating tickets. Wait a moment...'
  },
  netpayErrorMessages: {
    verifyCardDetails: "Please verify the card details",
    invalidCard: "Invalid card!",
    ticketsError: "An error occurred while generating tickets, please contact support.",
    payError: "An error occurred while making the payment",
    netpayError: "An unexpected error occurred, please refresh the page and try again",
    netpayProblem: "Collection service error. Please try again later.",
  },
  serviceUnavailableMessage: "Temporary, this service is unavailable. Thank you for your understanding."
}
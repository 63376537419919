import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/login',
  //   name: 'loginPage',
  //   component: () => import('../views/LoginView.vue')
  // },
  {
    path: '/cart',
    name: 'shoppingCart',
    component: () => import('../views/ShoppingCart.vue')
  },
  {
    path: '/sell',
    name: 'sell',
    component: () => import('../views/Sell.vue')
  },
  {
    path: '/tickets',
    name: 'userTickets',
    component: () => import('../views/UserTicketsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/myProfile',
    name: 'MyProfile',
    component: () => import('../views/PersonalInfoView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/event',
    name: 'events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/event-preview',
    name: 'eventPreview',
    component: () => import('../views/EventPreview.vue')
  },
  {
    path: '/*',
    name: 'notFoundView',
    component: () => import('../views/404PageView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   let token = JSON.parse(localStorage.getItem('user'))?.token_cliente
//   const requiresAuth = to.matched.some(route => route.meta.requiresAuth)
//   if (requiresAuth && !token) {
//     localStorage.setItem('redirectPath', to.fullPath);
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router

import { render, staticRenderFns } from "./FromNewUser.vue?vue&type=template&id=783e2d14&scoped=true"
import script from "./FromNewUser.vue?vue&type=script&lang=js"
export * from "./FromNewUser.vue?vue&type=script&lang=js"
import style0 from "./FromNewUser.vue?vue&type=style&index=0&id=783e2d14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783e2d14",
  null
  
)

export default component.exports
module.exports.messages = {
  welcome: '¡Bienvenido!',
  validationx:'Iniciando validación de cargo',
  TransTickets:'Su transacción tiene',
  MaxTickets:'Limite de boletos por transacción',
  TokenInvalid:'Inicia sesión o Registrate!',
  SelectTickets:'Seleccione algun boleto!',
  generateorden:'Generando orden',
  finishtransac:'Finalizando transacción',
  confirmpay:'Confirmando pago',
  invalidUser: '¡Usuario inválido!',
  desactivate: 'Desactivado por el momento',
  failedToLogin: '¡Error al iniciar sesión!',
  ticketsNumberMustZero: 'seleccione al menos un boleto!',
  ticketsNumberMustLessHundred: 'La cantidad de boletos debe ser menor a 20',
  ticketsMustMatch: '¡La cantidad de boletos seleccionados no coincide con su selección anterior!',
  ticketsUpdated: 'Carrito actualizado!',
  checkTheFormData: 'Verifique los datos del formulario',
  acceptYermsConditions: 'Acepte los términos y condiciones',
  validation: {
    required: 'Este campo es obligatorio.',
    isEmail: 'El correo electrónico debe ser válido.',
    validCard: 'Tarjeta Inválida',
    isPhone: 'El teléfono es inválido',
    validLocation: 'Por favor, seleccione un lugar de las opciones',
    invalidFields: 'Campos Invalidos',
    ValidateReCAPTCHA: 'Valida el reCAPTCHA',
    invalidCode: 'Código no válido',
    InvalidEmailPassword: 'Correo o contraseña Invalido'
  },
  tarjetValid:'Tarjeta Valida',
  permissionsRequired: 'Se requiere permisos.',
  needEmailMsg: 'Correo electrónico necesarios. ¿Desea otorgar los permisos?',
  tryAgain: 'Intentar nuevamente',
  canceltickets:'Desea Eliminar los boletos de este sitio?',
  UpdateInfoUser:'Datos Actualizados',
  msgConfirmation1:'Una vez utilizados tus boletos NO se podrán volver a utilizar.',
  updatedPass: "Contraseña actualizada",
  correctCode: "Código correcto. Ingrese nueva contraseña.",
  successfulPurchase: '¡Compra exitosa!',
  netpayProblem: 'Error del servicio de cobro. Inténtelo más tarde.',
  noEvents: "No hay eventos para el día de hoy",
  nextCloseDate: "Fecha más próxima",
  existingUser: "Este usuario ya existe",
  timeoutExpired: "Se ha vencido el tiempo de espera para la compra",
  soon: "Próximamente",
  reservationProblem: [
    "No hay suficientes lugares disponibles para alguna sección",
    "Alguno de los asientos ya no está disponible",
    "Cantidad de lugares no disponible",
    "No hay suficientes lugares disponibles"
  ],
  interest: "Esto podría interesarte...",
  ticketUploadError: "Error al cargar boletos",
  seeTicketsLegend: 'Ir a la sección "Mis Boletos" para imprimir tus boletos',
  paymentProcess: {
    validateCard: '1 de 5 - Validando Tarjeta...',
    generateTransaction: '2 de 5 - Generando transacción...',
    makeCharge: '3 de 5 - Realizando cobro...',
    validationRequired: 'Se requiere validación para la tarjeta, por favor llene los datos del formulario...',
    send3dSecure: '4 de 5 - Realizando validación...',
    verifyPayment: '5 de 5 - Verificando pago y generando boletos. Espere un momento...'
  },
  paymentErrors: {
    ticketsError: "Ocurrió un error al generar los boletos, por favor, contacte a soporte.",
    payError: "Ocurrió un error al realizar el pago",
    netpayError: "Ocurrió un error inesperado, por favor, refresque la página y vuelva a intentarlo"
  },
  noShopping: "No hay compras",
  noTickets: "Aún no hay boletos disponibles",
  cardDetailsNotice: "Ingrese los datos correspondientes a la tarjeta registrada en su cuenta.",
  fillAccountData: "Rellenar con los datos de la cuenta",
  notFoundMess: {
    defaultTitle: "No encontrado",
    defaultLegend: "Una disculpa... Algo salió mal",
    notFoundEvent: "Lamentablemente, el evento que está intentando acceder podría no estar disponible en este momento o haber sido cancelado."
  }
}
<template>
    <v-row justify="center">
    <v-dialog
      v-model="$root.dpfdialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      style="overflow:hidden !important;"
    >
      <v-card color="#4F4F4F" dark>
        <v-toolbar
          dark
          color="var(--color-primary)"
        >
          <v-toolbar-title>{{ $root.pdfCurrentTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            dark
            @click="closePdf"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <!-- <object data="mypdf.pdf" type="application/pdf" frameborder="0" width="100%" height="600px" style="padding: 20px;"> -->
          <!-- <embed :src="$root.pdfodc" width="100%" height="600px" type="application/pdf"/>  -->
        <!-- </object> -->

        <!-- <div class="g-savetodrive"
          :data-src="$root.pdfodc"
          data-filename="My Statement.pdf"
          data-sitename="My Company Name">
        </div> -->
        <v-card-text class="min-text">
          <object :data="$root.pdfodc" type="application/pdf" :class="$root.showAlertOnPdf ? 'MyObj': 'MyObj-noAlert'">
           <div align="center">
             <br>
               <h3 class="mt-9">{{$t("actions.pdf.oops")}}</h3>
               <br><br><br>
               <h3><a :href="$root.pdfodc" style="color:white;">{{$t("actions.pdf.download")}}</a></h3>
               <br><br>
               <img src="../../assets/sorry.webp" style="width:100%;" class="imgX"/>
           </div>
          </object>
        </v-card-text>
        
          <v-alert
            v-if="$root.showAlertOnPdf" 
            class="mx-auto"
            prominent
            type="error"
            icon="mdi-alert-octagram" >
            <v-row align="center">
              <v-col class="grow" cols="12" md="6">
                <span class="text-lg-h6 font-weight-bold">{{ $t("messages.seeTicketsLegend") }}</span>
              </v-col>
              <v-col class="grow" cols="12" md="6" style="text-align: right;">
                <v-btn dark color="success" @click="OpenMyTickets()">
                  Mis boletos
                </v-btn>
              </v-col>
              <!-- <v-col class="shrink">
                <v-btn color="white" class="black--text">{{ $t("actions.seeTickets") }}</v-btn>
              </v-col> -->
            </v-row>
          </v-alert>
        </v-card>

    </v-dialog>
    </v-row>
</template>
<script>
export default {
  name: 'PdfDialog',
  components: {
  },
  methods: {
    closePdf() {
      this.$root.dpfdialog = false;
    },
    showComponents() {
    },
    OpenMyTickets(){
      this.closePdf()
      if (this.$route.name != "userTickets") {
        this.$router.replace({ name: "userTickets" });
      }    
    }
  }
}
</script>

<style scoped>
.min-text {
  height: calc(90vh - 60px) !important; 
}
.MyObj{
    top: calc(0% + 64px);
    left: calc(0% + 10px);
    height: calc(90% - 70px); 
    width:98%;
    position: absolute; 
  }
.MyObj-noAlert{
    top: calc(0% + 64px);
    left: calc(0% + 10px);
    height: calc(100% - 66px);
    width:98%;
    position: absolute; 
  }
.imgX{
  display: none;
}
@media (max-width: 800px) {
  .min-text {
    height: calc(90vh - 30px) !important; 
  }
  .MyObj{
    top: calc(0% + 36px);
    left: calc(0% + 10px);
    height: calc(90% - 56px);
  }
  .MyObj-noAlert{
    overflow: hidden;
    top: calc(0% + 36px);
    left: calc(0% + 10px);
    width: calc(100% - 10px);
  }
  .imgX{
    display: block;
  }
}

</style>
import Vue from "vue";

Vue.prototype.$vEntorno = {
    eventCata:[],
    events:[],
    indexEveSel:null,
    eventselect: null,
    userEvents:[],
    tickets:[],
    temp_total:0,
    tempTotalTicket:0,
    dialogEvent:false,
    ticketsSection:[],
    indexEveSelSection:null,
    temp_eventSelect:null,
    textloading:'Loading..',
    dialogDates:false,
    showNoData: false,
    search: "",
    transac: ''
}

Vue.prototype.$FormClient = {
  form: {
    nombre: null,
    apellido: null,
    correo: null,
    celular: null,
    calle: null,
    cp: null,
    id_card: 0,
    cardnumber: "0000 0000 0000 0000",
    cardname: "97000",
    pais: "",
    estado: "",
    ciudad: "",
  },
  estados: null,
  ciudades: null,
}

/* Vue.prototype.$Inputnumeric = {
  total: 0,
  cantidad:0,
  precio:0,
  suma:0,
  tikcets:[],
  cantidad1:0,
  cantidad2:0
} */

Vue.prototype.$Inputnumeric = {
  timelimit:270,
  value:0,
  value2:0,
  total: 0,
  cantidad:0,
  precio:0,
  suma:0,
  tikcets:[],
  tikcets2:[],
  cantidad1:0,
  cantidad2:0
}

Vue.prototype.$NetpayY = {
  api:'pk_netpay_UzWCMcKSpBSGqEVHSoedxLXYb',
  secret:'sk_netpay_tHarvvNuZLZtlLveBaeRbqJzgpVjTnsSihrLAUAjOJZDU'
}
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"noselect",attrs:{"id":"app"}},[(_vm.$root.loading)?_c('Loading',{staticClass:"MyLoading",staticStyle:{"background":"#1c1c1c99 !important"}}):_vm._e(),(_vm.$root.showlogin)?_c('div',{staticClass:"login"},[_c('Login')],1):_vm._e(),(_vm.$root.dpfdialog)?_c('pdfview'):_vm._e(),_c('WarningInfoDialog',{attrs:{"showDialog":_vm.$root.showWarningDialog}}),(_vm.$route.name != 'sell' && 
      _vm.$route.name != 'eventPreview' && 
      _vm.$route.name != 'events' && 
      _vm.$route.name != 'loginPage')?_c('Header',{ref:"HeaderX",staticClass:"header-style"}):_vm._e(),_c('div',{staticClass:"body-page-container",class:[{
    'full-size': (_vm.$route.name != 'sell' || _vm.$route.name != 'eventPreview' || _vm.$route.name != 'events' || _vm.$route.name != 'loginPage')
  }]},[_c('router-view'),(_vm.$route.name != 'sell' && 
      _vm.$route.name != 'eventPreview' && 
      _vm.$route.name != 'events' && 
      _vm.$route.name != 'loginPage')?_c('Footer',{attrs:{"id":"MyFooterCool"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
module.exports.home = {
  footer: {
    connect: "Vamos a conectarnos",
    toHelpYou: 'Estamos aquí para ayudarte',
    business: 'Empresa',
    purchasePolicy: 'Politica de compra',
    noticePrivacy: 'Aviso de privacidad',
    allRightsReserved: 'Todos los derechos reservados',
  },
  footer2: {
    politicas:'Políticas de privacidad',
    transparencia:'Transparencia',
    cuenta:'Cuenta pública',
    apartado:'Apartado de integridad',
    directorio:'Directorio',
    programa:'Programas y convocatorias',
    ubication:'Ubicación:',
    mail:'Correo Electrónico:',
    tel:'Teléfono:',
    oficinaH:'Horario de Oficina:',
    oficinaH2:'Lunes a Viernes 9:00 a.m-6:00 p.m',
    CinemaH:'Horario del Cine:',
    CinemaH2:'Lunes a Domingo',
    SiteInt:'Sitios de Interés',
    Gob:'Gobierno de Yucatán',
    NKukulkan:'Noches de Kukulkán',
  },
  lostMyPassMessage: {
    part1: "Escriba o copie/pegue el código que se le envió a su correo,",
    part2: "agregue '0' a la izquierda si son menos de 6 números."
  }
}
module.exports.sales = {
  typePersonTitle: "Sélectionnez les participants pour l'événement",
  newSelec: "Nouvelle quantité",
  successPaymentMessages: {
     
  },
  failurePaymentMessages: {
    startNewShopMessage: "Une erreur s'est produite lors de l'achat",
  },
  netpaySuccessMessages: {
    validateCard: "1 sur 5 - Validation de la carte...",
    generateTransaction: "2 sur 5 - Génération de transaction...",
    makeCharge: "3 sur 5 - Effectuer le paiement...",
    validationRequired: "La validation de la carte est requise, veuillez remplir les détails du formulaire...",
    send3dSecure: "4 sur 5 - Effectuer la validation...",
    verifyPayment: "5 sur 5 - Vérification du paiement et génération des tickets. Attendez un moment..."
  },
  netpayErrorMessages: {
    verifyCardDetails: "Veuillez vérifier les détails de la carte",
    invalidCard: "Carte invalide!",
    ticketsError: "Une erreur s'est produite lors de la génération des tickets, veuillez contacter le support.",
    payError: "Une erreur s'est produite lors du paiement",
    netpayError: "Une erreur inattendue s'est produite, veuillez actualiser la page et réessayer",
    netpayProblem: "Erreur du service de collecte. Veuillez réessayer plus tard.",
  },
  serviceUnavailableMessage: "Temporairement, ce service est indisponible. Merci pour votre compréhension."
}
module.exports.tableHeaders = {
  rowsporpage: 'Filas por página',
  itemsPerPageAllText: 'Todos',
  eventName: 'Nombre del evento',
  city: 'Ciudad',
  state: 'Estado',
  eventPlace: 'Lugar del evento',
  zone: 'Zona',
  Seat: 'Asiento',
  row: 'Fila',
  column: 'Columna',
  price: 'Precio',
  transac:'TRANSACCIÓN',
  dtransac:'FECHA TRANSACCIÓN',
  ttransac:'HORA TRANSACCIÓN',
  ResumShop:'Resumen de compra',
  Amount:'Cantidad',
  Nomber:'Nombre del Boleto',
  Unitary:'Costo Unitario',
  subtotal:'Subtotal',
  DataPay:'Datos de pago',
  proof: "Comprobante",
  status: "Estatus"
}